@import '@iso/assets/variables.scss';

.request_status_wrapper {
  .status {
    background-color: $pending-color;
    color: #fff;
    padding: 7px 12px !important;
    border-radius: 18px;

    &.unassigned {
      background-color: $pending-color;
      color: #fff;
      padding: 7px 12px !important;
      border-radius: 18px;
    }
    &.declined {
      background-color: $declined-color;
      color: #fff;
      padding: 7px 12px !important;
      border-radius: 18px;
    }
    &.paused {
      background-color: $paused-color;
      color: #fff;
      padding: 7px 12px !important;
      border-radius: 18px;
    }
    &.incomplete {
      background-color: $incomplete-color;
      color: #fff;
      padding: 7px 12px !important;
      border-radius: 18px;
    }
    &.approved {
      background-color: $approved-color;
      color: #fff;
      padding: 7px 12px !important;
      border-radius: 18px;
    }
    &.overdue {
      background-color: $overdue-color;
      color: #fff;
      padding: 7px 12px !important;
      border-radius: 18px;
    }
    &.active {
      background-color: $approved-color;
      color: #fff;
      padding: 7px 12px !important;
      border-radius: 18px;
    }
  }
}
