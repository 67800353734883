@import "@iso/assets/variables.scss";
@import "bootstrap/scss/bootstrap.scss";

#right-part-wrapper-step3 {
  #coupon-code-img{
    width: 100%;
    margin-bottom: 30px;
  }
  #add-coupon-title{
    margin-bottom: 40px;
  }
  #add-coupon-code-btn{
    margin-left: 30px;
    color: #1C1634;
    font-size: 16px;
    text-decoration: underline;
    font-weight: bold;
    @media only screen and (max-width: 850px){
      margin-left: 17px;
    }
    @media only screen and (max-width: 500px){
      margin-left: 56px;
    }
    @media only screen and (max-width: 375px) {
      font-weight: 400;
    }
  }
  #apply-coupon-btn{
    background-color: #499E9E;
    border-radius: 6px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    height: 39px;
    width: 91px;
    border: unset;
    margin-left: 13.61px;
  }
  #submit-with-coupon-btn{
    background-color: #499E9E;
    border-radius: 6px;
    color: white;
    font-size: 16px;
    font-weight: bold;
    height: 60px;
    width: 100%;
    border: unset;
  }
  .submit-with-coupon{
    padding: 0px 43px 25px 43px;
    @media only screen and (min-width: 0px) and (max-width: 500px){
      padding: 0px 33px 25px 29px;
    }
    @media only screen and (min-width: 501px) and (max-width: 1024px){
      padding: 0px 30px 25px 29px;
    }
  }
  .coupon-code-container{
    width: 100%;
    margin-bottom: 40px;
    box-sizing: border-box;
    border: 1px solid #AFDAF5;
    border-radius: 6px;
    padding: 17px 43px 17px 43px;
    @media only screen and (min-width: 0px) and (max-width: 375px){
      padding: 17px 33px 25px 29px;
      margin-left: 37.5px;
      margin-right: 37.5px;
    }
    @media only screen and (min-width: 376px) and (max-width: 400px){
      padding: 17px 33px 17px 29px;
      margin-left: 37.5px;
      margin-right: 37.5px;
    }
    @media only screen and (min-width: 400px) and (max-width: 500px){
      padding: 17px 33px 17px 29px;
      margin-left: 37.5px;
      margin-right: 37.5px;
    }
    @media only screen and (min-width: 501px) and (max-width: 1024px){
      padding: 17px 30px;
    }
  }
  .coupon-form{
    width: 100%;
    display: inline-flex;
    position: relative;
  }
  .coupon-success-msg{
    font-weight: bold;
    font-size: 18px;
    right: 0px;
    top: 38px
  }
  .coupon-success-container{
    display: flex;
    justify-content: end;
    height: 30px;
  }
  .coupon-error-msg{
    font-weight: bold;
    text-align: left;
    font-size: 18px;
    color: #DE4B4B;
    margin-bottom: 0px;
    @media only screen and (max-width: 500px){
      font-size: 15px;
    }
  }
  .coupon-info {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 20px;
  }
  
  .submit-with-coupon-container{
    width: 100%;
    margin-bottom: 16px;
    box-sizing: border-box;
    border: 1px solid #AFDAF5;
    border-radius: 6px;
    @media only screen and (max-width: 500px){
      margin-left: 37.5px;
      margin-right: 37.5px;
    }
  }

  @media only screen and (min-width: 1024px){
    width: 490px;
  }
  .visa_master_img_wrapper {
    background-color: #fff;
    border: unset;
    position: relative;
    width: 130px;
    height: 30px;
    margin-left: -8px;
    img {
      position: relative;
      width: 100%;
      width: 130px;
      height: 30px;
    }
  }

  .power-stripe-container{
    margin: 0 auto;
  }

  @media (max-width: 768px) {
    .coupon-code-container .custom_input_stripe {
      width: calc(100% - 91px);
      font-size: 16px !important;
      line-height: 19px !important;
    }
  }

  .custom_input_stripe::placeholder{
    color: #A9B7C4;
  }

  .MuiCircularProgress-colorPrimary {
    color: white !important;
}

  .margin-signIn{
    margin-left: -14px;
    @media only screen and (max-width: 376px){
      margin-left: 80px;
      .custom_already_member_text{
        color: #6D7278;
      }
    }
    @media only screen and (min-width: 377px) and (max-width: 500px){
      margin-left: 80px;
      .custom_already_member_text{
        color: #6D7278;
      }
    }
  }

  .my-title.contribution{
    font-size: 30px;
    color: #1c1634;
    line-height: 64px;
    @media only screen and (max-width: 376px){
      font-size: 24px;
      line-height: 34px;
      margin-bottom: 1px;
    }
    @media only screen and (min-width: 377px) and (max-width: 500px){
      font-size: 24px;
    }
    .total_contribution {
      font-size: 36px;
      color: $blue_light_color;
      bottom: 0;
      // margin-left: 35px;
      margin-left: 15px;
      @media (min-width: 768px) and (max-width: 1024px) {
        font-size: 24px !important;
        margin-left: 10px;
      }
      @media only screen and (max-width: 376px){
        font-size: 24px;
        margin-left: 15px;
      }
      @media only screen and (min-width: 377px) and (max-width: 500px){
        font-size: 24px;
        margin-left: 15px;
      }
    }
    .total_contribution_original {
      font-size: 36px;
      color: black;
      bottom: 0;
      margin-left: 25px;
      @media (min-width: 768px) and (max-width: 1024px) {
        margin-left: 10px;
        font-size: 24px;
      }
      @media only screen and (max-width: 376px){
        font-size: 24px;
        margin-left: 15px;
      }
      @media only screen and (min-width: 377px) and (max-width: 500px){
        font-size: 24px;
        margin-left: 15px;
      }
    }
  }

  .our-gift-to-you{
    font-weight: bold;
    font-size: 30px;
    color: #1c1634;
    @media only screen and (max-width: 376px){
      font-size: 24px;
    }
    @media only screen and (min-width: 377px) and (max-width: 768px){
      font-size: 24px;
      line-height: 34px;
    }

    .total_contribution {
      font-size: 36px;
      color: $blue_light_color;
      bottom: 0;
      margin-left: 35px;
      @media (min-width: 768px) and (max-width: 1024px) {
        margin-left: 14px;
      }
      @media only screen and (max-width: 376px){
        font-size: 24px;
        margin-left: 25px;
      }
      @media only screen and (min-width: 377px) and (max-width: 500px){
        font-size: 24px;
        margin-left: 25px;
      }
    }
  }

  .sub-contribution-text{
    font-size: 14px;
    line-height: 22px;
    color: #6d7278;
    @media only screen and (max-width: 376px){
      padding-right: 75px;
    }
    @media only screen and (min-width: 377px) and (max-width: 500px){
      padding-right: 120px;
    }
    @media only screen and (max-width: 768px){
      margin-bottom: 27px !important;
      font-style: italic;
    }
  }

  .pay_btn {
    width: 100%;
    height: 60px;
  }

  .ml-firefox{
    margin-left: -1px;
  }
  @media only screen and (min-width: 576px) and (max-width: 768px) {
    margin-top: 0;
  }
  .is_sign_in_not_user{
    @media only screen and (max-width: 376px){
      display: flex;
      justify-content: start;
      margin-left: -15px;
      margin-bottom: 20px;
    }
    @media only screen and (min-width: 377px) and (max-width:500px){
      display: flex;
      justify-content: start;
      margin-left: -15px;
      margin-bottom: 25px;
    }
  }
  .margin_top_contribution{
    @media only screen and (max-width: 500px){
      margin-top: 15px;
    }
    @media only screen and (min-width: 1025px){
      margin-top: 54px!important;
    }
  }
  .margin_top_gift_coupon{
    margin-top: 54px;
    @media only screen and (max-width: 500px){
      margin-top: 76px;
    }

    @media only screen and (max-width: 768px){
      margin-top: 23px;
    }
  }
}
