.custom-scrollbar {
  &::-webkit-scrollbar-track {
    background: #ecf4f8;
    width: 6px;
  }

  &::-webkit-scrollbar {
    width: 6px;
  }

  &::-webkit-scrollbar-thumb {
    background: #499e9e;
    border-radius: 4px;
    width: 6px;
  }
}
