.faq__wrapper {
  .MuiAccordion-root:before {
    background: none;
  }
}

// @media  (min-width: 1920px) {
//   .faq__wrapper {
//     padding-left: 390px !important;
//     padding-right: 390px !important;
//   }
// }

@media (max-width: 500px) {
  .faq-content {
    //white-space: pre-line;
    letter-spacing: 0 !important;
  }
  .faq__wrapper {
    .MuiCollapse-container {
      p {
        &.MuiTypography-root {
          max-width: 270px;
        }
      }
    }
  }
}
