@import '@iso/assets/variables.scss';

.pricing_table_wrapper {
  color: #000000;
  @media only screen and (max-width: 991px) {
    padding-right: 50px;
  }

  .select_fake_wrapper {
    width: 128px;
    height: 96px;
    text-align: center;
    border: 1px solid #DBDEE7;
    border-radius: 5px;
    background-position: center;

    .description {
      font-size: 12px;
      line-height: 16px;
    }

    &.selected {
      border: 2px solid $hippie-blue-background;
      background-color: $gray-ish-color;
    }
  }

  .margin_contribution{
    margin-left: 10px;
  }

  @media only screen and (min-width: 1025px){
    .margin-price-3{
      margin-right: 40px!important;
      margin-left: 5px!important;
    }

    .margin-price-4{
      margin-right: -10px!important;
      margin-left: 5px!important;
    }
  }

}

.frequency_wrapper {
  color: $black-color;
  .custom-control{
    margin-left: -16px;
  }
}

@media only screen and (max-width: 500px){
  .frequency_wrapper{
    .custom_frequency_data{
      font-family: 'BrandonText';
      font-weight: 400;
      font-size: 18px;
      line-height: 30px;
    }
  }
  .pricing_table_wrapper{
    .select_fake_wrapper{
      height: 95px;
      &.my-2{
        margin-top: 4px!important;
        margin-bottom: 4px!important;
      }
    }
  }
}
@media only screen and (min-width: 1025px){
  .frequency_wrapper.mt-3{
    margin-top: 32px!important;
  }
  .total_contribution.mt-4{
    margin-top: 30px!important;
  }
}

@media only screen and (min-width: 1025px){
  #left-part-wrapper-step2.jewish {
    .select_fake_wrapper {
      width: 145px!important;
    }
  }
}

.custom_table_margin_price.mt--30px {
  margin-top: -30px !important;
}

.custom_table_margin_price.mt--25px {
  margin-top: -25px !important;
}

.custom_table_margin_price {

  .prayer{
    height: 96px;
  }

  .prayer-option {
    position: relative;

    .prefix-option, .suffix-option {
      position: absolute;
      background-repeat: no-repeat;
      background-size: contain;
    }

    &.prayer-candle-2 {
      .prefix-option {
        top: 9px;
        left: calc(50% + 28px);
        width: 7px;
        height: 13px;
        background-image: url('/contributions_image/blue-star-bling.png');
      }

      .suffix-option {
        left: 50%;
        transform: translateX(-50%);
      }

      &.prayer-partner_no-preference,
      &.prayer-partner_anglican,
      &.prayer-partner_eastern-orthodox,
      &.prayer-partner_non-denomination,
      &.prayer-partner_catholic,
      &.prayer-partner_protestant,
      &.prayer-partner_evangelical
      {
        height: 111px;

        .suffix-option {
          width: 52px;
          height: 43px;
          background-image: url('/contributions_image/non-denom/candle-2.png');
          bottom: 2px;
        }
      }

      &.prayer-partner_jewish {
        height: 128px;

        .suffix-option {
          width: 41px;
          height: 83px;
          background-image: url('/contributions_image/jewish/candle-2.png');
          bottom: -18px;
        }
      }
    }

    &.prayer-candle-3 {
      .prefix-option {
        top: 9px;
        left: calc(50% + 28px);
        width: 7px;
        height: 13px;
        background-image: url('/contributions_image/yellow-star-bling.png');
      }

      .suffix-option {
        left: 50%;
        transform: translateX(-50%);
      }

      &.prayer-partner_no-preference,
      &.prayer-partner_anglican,
      &.prayer-partner_eastern-orthodox,
      &.prayer-partner_non-denomination,
      &.prayer-partner_catholic,
      &.prayer-partner_protestant,
      &.prayer-partner_evangelical
      {
        height: 130px;

        .suffix-option {
          width: 84px;
          height: 120px;
          background-image: url('/contributions_image/non-denom/candle-3.png');
          bottom: 2px;
        }
      }

      &.prayer-partner_jewish {
        height: 158px;

        .description {
          line-height: 24px;
        }

        .suffix-option {
          width: 46px;
          height: 76px;
          background-image: url('/contributions_image/jewish/candle-3.png');
          bottom: -9px;
        }
      }
    }

    &.prayer-candle-4 {
      height: 145px;

      .prefix-option {
        top: 9px;
        left: calc(50% + 28px);
        width: 8px;
        height: 16px;
        background-image: url('/contributions_image/blur-blue-star-bling.png');
      }

      .suffix-option {
        left: 50%;
        transform: translateX(-50%);
      }

      &.prayer-partner_anglican {
        height: 141px;

        .suffix-option {
          width: 46px;
          height: 65px;
          background-image: url('/contributions_image/anglican/candle-4.png');
          bottom: 5px;
        }
      }

      &.prayer-partner_catholic {
        height: 145px;

        .suffix-option {
          width: 55px;
          height: 68px;
          background-image: url('/contributions_image/catholic/candle-4.png');
          bottom: 6px;
        }
      }

      &.prayer-partner_eastern-orthodox {
        height: 145px;

        .suffix-option {
          width: 53px;
          height: 53px;
          background-image: url('/contributions_image/orthodox/candle-4.png');
          bottom: 14px;
        }
      }
    }
  }
}
