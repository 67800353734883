.profile_info__form {

  .safari-input-disabled {
    input:disabled {
      -webkit-text-fill-color:#000; /* Override iOS / Android font color change */
      -webkit-opacity: 1;
    }
  }

  .my-picker {
    input::placeholder {
      color: black;
      opacity: 1;
    }
  }
  .MuiInputLabel-formControl {
    font-size: 18px;

    @media (max-width: 1024px) {
      font-size: 14px;
    }
  }
}

.account_page__profile {
  .mobile_avatar {
    float: left;
    margin-right: 10px;
    img {
      width: 40px;
      height: 40px;
      margin-top: 0;
    }
  }
  @media only screen and (max-width: 500px) {
    .MuiCardHeader-action {
      margin-top: 0px;
    }
  }
}

.MuiSnackbar-anchorOriginBottomCenter {
  @media only screen and (max-width: 500px) {
    left: 0px !important;
    .MuiAlert-message {
      font-size: 20px;
      text-align: unset;
    }
    .MuiAlert-root {
      padding-left: 20px;
    }
    .MuiAlert-action {
      right: 20px;
      margin-right: 0px;
    }
  }
}
.view {
  .MuiSelect-selectMenu {
    padding: 0 !important;
  }
}