.account_page__deactivate_account {
  .textArea__focused:focus { 
    outline: none;
  }
  .textArea__focused{
    border: 1px solid rgba(0, 0, 0, 0.4);
    @media only screen and (max-width: 500px){
      border: 1px solid #D9E9F1;
    }
  }
  
  textarea::placeholder{
    color: black;
  }
  textarea{
    -webkit-appearance: none!important;
    box-shadow: none!important;
    -moz-appearance: none!important;
    appearance: none!important;
  }
  .deactivate_account__button {
    width: 173px;
    padding: 15px 10px;
    @media only screen and (max-width: 500px){
      margin-top: 10px!important;
    }
  }

  .button__save {
    height: 48px;
    font-size: 16px;

    &--submit {
      width: 156px;
    }

    &--cancel {
      width: 173px;
      margin-right: 20px !important;
    }
  }

  .content{
    padding: 30px 35px;
    @media only screen and (min-width: 1024px){
      padding: 30px 112px 30px 48px;
    }
    @media only screen and (min-width: 350px) and (max-width: 410px){
      padding-left: 35px;
      padding-right: 21px;
    }
    @media only screen and (max-width: 345px){
      padding-left: 25px;
      padding-right: 10px;
    }
  }

  .content_decision{
    padding: 30px 35px;
    @media only screen and (min-width: 376px) and (max-width: 500px){
      padding-left: 58px;
    }
  }
}
