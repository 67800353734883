.GiftMessage {
    .padding_right_handle_click {
        @media only screen and (min-width: 1025px) {
            padding-right: 45px !important;
        }
    }

    .padding_gift_title_message {
        @media only screen and (min-width: 1025px) {
            padding-left: 20px;
        }
    }
    &.custom_margin_gift_message{
        @media only screen and (min-width: 1025px){
            margin-top: -5px!important;
            margin-bottom: -20px!important;
        }
    }

    .custom_gift_message_textarea {
        label {
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                line-height: 20px !important;
                padding-right: 20px !important;
            }
        }

        textarea, .text-dear {
            @media only screen and (min-width: 768px) and (max-width: 1024px) {
                margin-top: 30px !important;
                padding-right: 10px;
            }
        }
    }
    .MuiInputBase-inputMultiline{
        @media only screen and (max-width: 376px){
            padding: 10px 0px 0px 0px!important;
            font-size: 18px;
            line-height: 24px;
        }
        @media only screen and (min-width: 376px) and (max-width: 500px){
            padding: 15px 33px 0px 0px!important;
            font-size: 18px;
            line-height: 24px;
        }
    }
    .custom_title_gift_message {
        width: 490px !important;
        @media only screen and (max-width: 376px) {
            #recipient_name {
                color: #1C1634;
                font-family: 'BrandonText';
                font-weight: 400;
                font-style: normal;
                font-size: 18px;
                line-height: 24px;
                padding-right: 28px !important;
            }
            #recipient_name-label {
                padding-right: 30px;
                line-height: 18px;
            }
            .MuiInput-underline:before{
                width: 300px!important;
                margin-left: -10px!important;
            }
        }
        @media only screen and (max-width: 500px) and (min-width: 377px) {
            #recipient_name {
                color: #1C1634;
                font-family: 'BrandonText';
                font-weight: 400;
                font-style: normal;
                font-size: 18px;
                line-height: 24px;
                padding-right: 65px !important;
            }
            #recipient_name-label {
                padding-right: 30px;
                line-height: 18px;
            }
            .MuiInput-underline:before{
                width: 340px!important;
                margin-left: -10px!important;
            }
        }
        @media only screen and (max-width: 500px) and (min-width: 377px) {
            width: 340px !important;
            max-width: 340px !important;
            margin-left: -8px;
        }
        @media only screen and (max-width: 376px) {
            width: 300px !important;
            max-width: 300px !important;
            margin-left: -8px;
        }
    }
    .custom_padding_card_wrapper_gift_message{
        @media only screen and (max-width: 500px){
            padding-bottom: 48px!important;
        }
    }
    .padding_gift_title_message{
        @media only screen and (min-width: 1025px){
            padding-left: 20px;
        }
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px){
        .custom_label_gift_message_is_not_gift{
            padding-right: 10px;
        }
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
        .custom_label_gift_message_is_not_gift{
            padding-right: 60px;
        }
    }
    @media only screen and (min-width: 1025px){
        .custom_content_gift_message_is_not_gift{
            padding-right: 15px;
        }
    }
    @media only screen and (max-width: 376px){
        .custom_content_gift_message_is_not_gift{
            padding-right: 25px;
        }
    }
  .hidden-underline {
    .MuiInput-underline {
      &:before {
        border-bottom: none!important;
      }
    }
    .MuiInputBase-inputAdornedStart {
      padding-left: 5px!important;
    }

    @media only screen and (max-width: 600px) {
      .text-dear {
        padding: 10px 0px 0px 0px !important;
      }
      .MuiInputBase-adornedStart {
        div {
          div {
            padding-top: 4px;
          }
        }
      }
    }
    margin-bottom: 5px;
  }
}
