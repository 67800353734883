.custom-prayertypes {
  .MuiSelect-root {
    margin-left: 17px;
  }
  @media only screen and (min-width: 1025px){
    .MuiSelect-root {
      margin-left: 0px!important;
    }
  }
}
.custom_label_prayer_type_drop_down{
  @media only screen and (min-width: 1025px){
    padding-left: 30px;
    padding-top: 8px;
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px){
    margin-bottom: 0px;
  }
}
@media only screen and (max-width: 500px) {
  .custom-prayertypes {
    .MuiSelect-root {
      margin-left: 0px;
    }
  }
}
@media only screen and (min-width: 1025px){
  .width_most_request_prayer{
    max-width: 505px!important;
  }
}
