@import "@iso/assets/variables.scss";

.wrapper_request_details {
  .custom-control.custom-checkbox {
    white-space: nowrap;
  }

  .margin-for-whom{
    margin-top: -4px;
  }
  .gift_notify_margin {
    @media only screen and (min-width: 1025px){
      margin-bottom: 23px;
    }
  }

  @media only screen and (max-width: 900px) {
    .margin-for-whom{
      margin-top: 10px;
    }

    .gift_notify_margin{
      margin-top: -7px;
      margin-bottom: 9px;
    }
  }
  @media only screen and (max-width: 500px) {
    .step3-request-details-input {
      padding-left: 5px !important;
    }
    .gift_notify_margin {
      margin-top: 15px;
      margin-bottom: 5px;
    }
  }

  @media (min-width: 768px) and (max-width: 1024px) {
    .gift_notify_margin{
      margin-top: -4px!important;
      margin-bottom: 20px!important;
    }
  }
  .custom-checkbox .custom-control-label::before,
  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-checkbox
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    // border-color: #499e9e;
    height: 25px;
    width: 25px;
    top: 10px;
  }

  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-checkbox
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: #499e9e;
  }

  .custom-control-input:disabled ~ .custom-control-label::before {
    background-color: transparent;
    border: 1px solid #DBDEE7;
  }

  .custom-control-input:checked ~ .custom-control-label::after,
  .custom-checkbox
    .custom-control-input:checked
    ~ .custom-control-label::after {
    position: absolute;
    top: 22px;
    transform: translate(-50%, -50%);
    background-size: 13px;
    left: -12px;
  }

  label.custom-control-label[for="gift_notification"] {
    color: black !important;
    font-size: 16px !important;
    padding-left: 10px;
  }
  .custom_card_title {
    font-size: 18px;
    font-weight: normal;
  }
  .margin_top_for_whom{
    @media only screen and (min-width: 1025px){
      margin-top: 30px;
    }
  }
  .margin_top_prayer_type{
    @media only screen and (min-width: 1025px){
      margin-top: 0!important;
    }
  }
  .margin_top_label_reason{
    @media only screen and (min-width: 1025px){
      margin-top: 0px!important;
    }
    @media only screen and (max-width: 500px){
      margin-top: 25px;
    }
  }
  .margin_top_label_reason_is_gift{
    @media only screen and (min-width: 1025px){
      margin-top: -15px!important;
    }
    @media only screen and (max-width: 500px){
      margin-top: 15px;
    }
  }
  .margin_top_label_reason_is_edit{
    @media only screen and (min-width: 1025px){
      margin-top: -20px!important;
    }
    @media only screen and (max-width: 500px){
      margin-top: 0px;
    }
  }
  .margin_top_recipient_email{
    @media only screen and (min-width: 1025px){
      margin-top: 10px!important;
    }
    @media only screen and (max-width: 500px){
      margin-top: 29px;
    }
    .custom_recipient_email_width{
      @media only screen and (max-width: 376px){
        width: 315px!important;
        margin-left: -13px;
        .MuiInput-underline:before{
          margin-left: -10px!important;
        }
      }
      @media only screen and (max-width: 500px) {
        .MuiInput-formControl {
          margin-top: 0px!important;
        }
      }
      @media only screen and (min-width: 377px) and (max-width: 500px){
        width: 353px!important;
        margin-left: -12px;
        .MuiInput-underline:before{
          margin-left: -10px!important;
        }
        label{
          padding-left: 13px!important;
        }
        input{
          padding-left: 3px!important;
        }
      }
    }
    .custom_error_recipient_mail{
      @media only screen and (max-width: 500px){
        margin-left: -7px;
      }
    }
  }
  .margin_top_sender_name_is_not_gift{
    @media only screen and (max-width: 500px){
      margin-top: 20px;
    }
  }
  .margin_top_sender_name{
    @media only screen and (min-width: 1025px){
      margin-top: 10px!important;
    }
    @media only screen and (max-width: 500px){
      margin-top: 15px;
      margin-bottom: -10px;
    }
    .custom_recipient_email_width{
      @media only screen and (max-width: 376px){
        width: 315px!important;
        margin-left: -13px;
        .MuiInput-underline:before{
          margin-left: -10px!important;
        }
      }
      @media only screen and (min-width: 377px) and (max-width: 500px){
        width: 353px!important;
        margin-left: -12px;
        .MuiInput-underline:before{
          margin-left: -10px!important;
        }
        label{
          padding-left: 13px!important;
        }
        input{
          padding-left: 3px!important;
        }
      }
    }
    .margin_right_sender_name{
      @media only screen and (max-width: 376px){
        width: 315px!important;
        margin-left: -13px;
        .MuiInput-underline:before{
          margin-left: -10px!important;
        }
      }
      @media only screen and (max-width: 500px) {
        .MuiInput-formControl {
          margin-top: 0px!important;
        }
      }
      @media only screen and (min-width: 377px) and (max-width: 500px){
        width: 353px!important;
        margin-left: -12px;
        .MuiInput-underline:before{
          margin-left: -10px!important;
        }
        label{
          padding-left: 13px!important;
        }
        input{
          padding-left: 3px!important;
        }
      }
    }
    .custom_error_sender_name{
      @media only screen and (max-width: 500px){
        margin-left: -7px;
      }
    }
  }
  .padding_handle_click{
    @media only screen and (min-width: 1025px){
      padding-right: 30px;
    }
  }
  .custom_label_their_name{
    @media only screen and (min-width: 900px){
      width: 505px;
    }
  }
  .padding_right_handle_click{
    @media only screen and (min-width: 1025px){
      padding-right: 45px!important;
    }
  }
  .margin_right_sender_name{
    @media only screen and (min-width: 1025px){
      margin-left: -14px!important;
      width: 240px!important;
    }
  }
  .margin_right_recipient_name{
    @media only screen and (min-width: 1025px){
      margin-right: 15px;
    }
    @media only screen and (max-width: 500px) {
      .MuiInput-formControl {
        margin-top: 10px!important;
      }
    }
    @media only screen and (max-width: 376px){
      width: 315px!important;
      margin-left: -2px;
      .MuiInput-underline:before{
        margin-left: -10px!important;
      }
    }
    @media only screen and (min-width: 377px) and (max-width: 500px){
      width: 353px!important;
      margin-left: -2px;
      .MuiInput-underline:before{
        margin-left: -10px!important;
      }
      label{
        padding-left: 13px!important;
      }
      input{
        padding-left: 3px!important;
      }
    }
  }
  .custom_margin_for_who{
    @media only screen and (min-width: 1025px){
      margin-top: -3px;
      margin-bottom: 3px;
    }
    @media only screen and (max-width: 500px){
      padding-top: 8px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px){
      padding-left: 5px;
    }
  }
  .margin_bottom_reason_request_detail{
    @media only screen and (max-width: 500px){
      margin-bottom: 35px!important;
    }
  }
  .custom_reason_request_detail{
    width: 500px!important;
    @media only screen and (min-width: 1025px){
      padding-top: 17px!important;
      label{
        padding-top: 13px!important;
        padding-left: 25px!important;
      }
      textarea{
        padding-left: 25px!important;
      }
    }
    @media only screen and (max-width: 500px) {
      margin-bottom: 15px;
      .MuiInput-formControl {
        margin-top: 0px!important;
      }
    }
    @media only screen and (max-width: 376px){
      width: 315px!important;
      margin-left: -2px;
      margin-top: 10px;
      .MuiInput-underline:before{
        margin-left: -10px!important;
      }
    }
    @media only screen and (min-width: 377px) and (max-width: 500px){
      width: 353px!important;
      margin-left: -2px;
      .MuiInput-underline:before{
        margin-left: -10px!important;
      }
      label{
        padding-left: 13px!important;
      }
      textarea{
        padding-left: 3px!important;
      }
    }
  }
  .custom_recipient_name_is_not_edit{
    @media only screen and (max-width: 500px){
      margin-top: 15px;
    }
  }
  .custom_recipient_name_is_edit{
    @media only screen and (max-width: 500px){
      margin-top: 20px;
    }
  }
  .custom_error_message_sender_name{
    @media only screen and (min-width: 768px) and (max-width: 1024px){
      padding-left: 0!important;
      margin-left: 0px!important;
    }
  }
  .custom_label_color_hover{
    label{
      color: #499e9e!important;
    }
  }
  &.custom_request_detail_is_gift_top{
    .custom_reason_request_detail{
      @media only screen and (max-width: 500px){
        margin-bottom: 5px;
      }
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .custom_sender_name_is_not_gift{
      margin-top: -5px!important;
    }
    .margin_top_label_reason{
      margin-top: -5px!important;
    }
    .custom_margin_form_group{
      margin-bottom: -5px;
    }
    .custom_gift_notify_not_edit{
      margin-bottom: 30px!important;
    }
    .custom_for_whom_is_edit{
      padding: 0;
      padding-left: 10px;
    }
    .margin_right_recipient_name{
      margin-top: 25px;
      input{
        padding-right: 10px;
      }
    }
    .custom_recipient_email_width{
      textarea{
        padding-right: 10px;
      }
    }
    .margin_top_sender_name{
      margin-top: 0px!important;
    }
    .margin_top_label_reason_is_edit{
      margin-top: -15px!important;
    }
    // .margin_right_sender_name{
    //   margin-right: -5px!important;
    // }
    .custom_select_religion{
      padding-left: 5px;
    }
  }
  @media only screen and (min-width:376px) and (max-width: 500px){
    .custom_reason_myself_edit{
      margin-top: 18px;
    }
    .custom_reason_for_someone_else{
      margin-top: 25px;
    }
  }
  @media only screen and (min-width: 1024px){
    .custom_for_whom_is_edit{
      margin-top: 0px!important;
      border-bottom: 2px solid #499e9e;
      height: 59px;
      margin-left: -10px;
      max-width: 238px;
      padding-left: 25px;
      padding-top: 8px;
      z-index: 100;
      .custom_select_religion{
        &.MuiInput-underline:before{
          border: none!important;
        }
      }
      .custom_select_religion{
        .MuiSelect-select:focus{
          background-color: inherit!important;
        }
      }
    }
    .margin_right_recipient_name{
      margin-right: 5px;
      margin-left: 10px!important;
    }
  }
  .MuiSelect-select:focus{
    background: none;
  }
  @media only screen and (max-width: 375px){
    .margin_top_for_myself{
      margin-top: 20px!important;
    }
  }
  @media only screen and (min-width: 768px){
    .custom_error_message_recipient_name{
      margin-bottom: 15px!important;
    }
  }
}
