@import "bootstrap/scss/bootstrap.scss";

.wrapper_completion_details {
  height: 100%;
  .completion_details{
    margin-left: -155px;
  }

  .custom_height_for_display_value {
    min-height: 90vh;
    @include media-breakpoint-up(md) {
      min-height: 50vh;
    }
    @include media-breakpoint-up(lg) {
      min-height: 20vh;
    }
  }
}

@media only screen and (max-width: 500px) {
  .wrapper_completion_details {
    .translation_wrapper {
      .request_item_value_css {
        margin-left: 12px;
      }
    }
  }
}