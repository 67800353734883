body {
  overflow-y: auto !important;
  height: fit-content !important;
}
.react-datepicker-wrapper {
	display: block !important;
}

.custom-radio{
    .custom-control-label{
      padding-left: 14px;
    }

    .custom-control-label::before,
    .custom-control-label::after
    {
      background-size: 16px;
      width: 24px;
      height: 24px;
      top: 0px;
    }

    // .custom-control-input:checked ~ .custom-control-label::before {
    //   border-color: $hippie-blue-color;
    //   background-color: $hippie-blue-color;
    // }
    .custom-control-input ~ .custom-control-label::before{
      box-shadow: none;
    }

    .custom-control-input ~ .custom-control-label::before{
      box-shadow: none;
    }

    .custom-control-input ~ .custom-control-label::after{
      width: 24px;
      height: 24px;
      border-radius: 50%;
    }
  }

  .checkbox_custom{
    .custom-control-label{
      padding-left: 14px;
    }

    .custom-control-label::before,
    .custom-control-label::after{
      width: 25px;
      height: 25px;
      top: 0px;

    }

    .custom-control-input ~ .custom-control-label::before{
      box-shadow: none;
    }

 	.form-check-input{
	  margin-top: 0;
      margin-left: -36px;
      width: 25px;
      height: 25px;
 	}
 }

.font-size-14px{
  font-size: 14px;
}

.font-size-16px{
   font-size: 16px;
}

.font-size-18px{
  font-size: 18px;
}

.font-size-20px{
  font-size: 20px;
}

.font-size-22px{
    font-size: 22px;
}

.font-size-36px{
  font-size: 36px;
}

.badge-background {
  background-color: #999999;
}

.custom-pl-22px {

  @media only screen and (max-width: 768px){
    padding-left: 22px !important;
  }
}
