.submitBtn {
  font-size: 16px !important;
  width: 173px !important;
  @media only screen and (max-width: 500px) {
    width: 153px !important;
  }
}

.cancelBtn {
  font-size: 16px !important;
  width: 173px !important;
  box-shadow: none !important;
  @media only screen and (max-width: 500px) {
    width: 120px !important;
  }
}

.button__editProfile {
  width: 90px !important;
  font-family: BrandonText;
  font-size: 16px !important;
  font-weight: 600 !important;
  letter-spacing: 0.3px;
  @media only screen and (max-width: 500px) {
    min-width: 75px !important;
    width: 75px !important;
    padding: 0px !important;
    height: 38px !important;
    padding: 5px !important;
  }
}
