.save_stripe_card_form {
  .custom_stripe_input_wrapper {
    border: 1px solid #dbdee7;
    border-radius: 5px;
    padding: 16px;
  }
  .save_stripe_header {
    font-weight: bold;
    font-size: 22px;
    color: #000000;
  }

  .checkbox_custom{
    font-size: 18px;
    color: #1c1634;
  }
  
  .my-control {
    input:-webkit-autofill{
      -webkit-box-shadow: 0 0 0px 1000px white inset;
      background-color: transparent;   
    }

    .MuiFormLabel-root {
      font-family: BrandonText;
      font-size: 18px;
      color: #000000; 
    } 

    .MuiFormLabel-root,
    .MuiFilledInput-input{
      margin-left: 5px; 
      color: #000000;
      font-family: sans-serif;
      font-size: 18px;
    }  
  }
}
