.calendarContainer {
  * {
    font-family: BrandonText;
  }
  .heading {
    button{
      outline: none;
      svg {
        height: 16px;
        width: 16px;
      }
      &.title {
        border: 0;
        font-weight: 600;
        font-size: 18px;
        padding-top: 9px;
      }
    }
  }
  .monthsList {
    margin-top: 25px;

    div {
      button {
        border: 0;
        border-radius: 6px;
        float: left;
        height: 30px;
        padding: 0;
        margin-bottom: 10px;
        margin-top: 10px;
        &:disabled{
          cursor: not-allowed;
        }
      }
    }

    .selected {
      button {
        background: #499E9E!important;
        &:hover{
          background: #499E9E!important;
        }
      }
    }
  }
  .daysOfWeek {
    display: none;
  }
  .dayPickerContainer {
    .dayWrapper {
      button {
        &:hover{
          background: #eee!important;
        }
        font-size: 16px;
      }
      //&.tooltip-datepicker {
      //  &.today {
      //    //position: relative;
      //    .tooltiptext {
      //      visibility: hidden;
      //      font-style: italic;
      //      font-weight: normal;
      //      font-size: 18px;
      //      line-height: 32px;
      //      background-color: #D9E9F1;
      //      color: #000;
      //      text-align: left;
      //      border-radius: 6px;
      //      z-index: 1;
      //      width: 329px;
      //      top: 115%;
      //      left: -100%;
      //      margin-left: -60px;
      //      position: absolute;
      //      padding: 10px 30px 10px 26px;
      //
      //
      //      &:before {
      //        content: '';
      //        position: absolute;
      //        z-index: 1;
      //        left: 11px;
      //        right: 0;
      //        top: 13px;
      //        bottom: 13px;
      //        width: 0;
      //        border: 2px solid red;
      //        border-radius: 2px;
      //      }
      //    }
      //    button {
      //      &:disabled {
      //        color: #000000;
      //        cursor: pointer;
      //      }
      //    }
      //    /* Show the tooltip text when you mouse over the tooltip container */
      //    &.selected {
      //      .tooltiptext {
      //        visibility: visible;
      //      }
      //
      //      &::after {
      //        visibility: visible;
      //      }
      //      button {
      //        background: #de4b4b!important;
      //        color: #fff!important;
      //      }
      //    }
      //
      //    &:hover {
      //      .tooltiptext {
      //        visibility: visible;
      //      }
      //
      //      &::after {
      //        visibility: visible;
      //      }
      //      button {
      //        background: #de4b4b!important;
      //        color: #fff!important;
      //      }
      //    }
      //
      //    &::after {
      //      visibility: hidden;
      //      content: " ";
      //      position: absolute;
      //      bottom: -6px;
      //      left: 50%;
      //      right: auto;
      //      margin-left: -5px;
      //      border-width: 5px;
      //      border-style: solid;
      //      border-color: transparent transparent #D9E9F1 transparent;
      //    }
      //  }
      //}
    }
    .selected {
      button {
        background: #499E9E!important;
      }
      &:hover {
        button {
          background: #499E9E!important;
        }
      }
      &.disabled {
        &:hover {
        button {
            background: #eeeeee !important;
          }
        }
      }
    }
    .disabled {
      button {
        background: none!important;
      }
    }

    .today {
      button {
        background: none;
        border: 0 !important;
      }
    }
  }

  .yearsList {
    .selected {
      button {
        background: #499E9E!important;
      }
      &:hover {
        button {
          background: #499E9E!important;
        }
      }
      &.disabled {
        &:hover {
          button {
            background: #eeeeee !important;
          }
        }
      }
    }
  }
}

.tether-enabled {
  z-index: 10;
}
