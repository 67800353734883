@import "bootstrap/scss/bootstrap.scss";

.title_adapter {
  .title_anglican {
    font-weight: 400;
    font-size: 18px;
  }
  .title_image {
    max-width: 100%;
  }
  .author {
    max-width: 100px;
    @include media-breakpoint-up(md) {
      max-width: 100%;
    }
  }
  @media only screen and (max-width: 376px){
    .custom_title_image_orthodox{
      max-width: 302px!important;
    }
    .custom_title_anglican{
      padding: 0px;
      .title_anglican{
        color: #1C1634;
      }
    }
    .custom_title_image_anglican.mb-3{
      margin-bottom: 15px!important;
    }
    .custom_title_image_anglican.mb-3{
      margin-bottom: 15px!important;
    }
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    .custom_title_image_orthodox{
      max-width: 340px!important;
    }
    .custom_title_anglican{
      padding: 0px;
      .title_anglican{
        color: #1C1634;
      }
    }
  }
}
