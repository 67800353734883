@import "@iso/assets/variables.scss";

.admin_support_request_wrapper {
  .edit_btn,
  .edit_btn:hover,
  .btn_responded {
    background-color: $light-gray-color!important; 
    border: none !important; 
  }

  .delete_btn,
  .delete_btn:hover,
  .delete_btn:active {
    background-color: #f6ccdb !important;
    color: #000000 !important;
    border:none;
  }
  .support_note {
    color: $light-gray-color !important;
    font-size: 12px;
  }
  .responded_hr {
    width: 50%;
    border: 2px solid #999999;
  }
}
