@import "bootstrap/scss/bootstrap.scss";
@import "@iso/assets/variables.scss";
.hippie-side-bar {
  ul {
    li:hover {
      background-color: $oracle-color;
    }
  }
  
  .MuiTypography-root{
    font-weight: bold;
  }

  .MuiAccordionDetails-root{
    padding-left: 30px;
    cursor: pointer; 
  }

  .MuiAccordionDetails-root:hover,
  .MuiButtonBase-root:hover{
    background-color: $oracle-color;
  }
}
