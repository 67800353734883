#left-part-wrapper-step3 {
  .card {
    ._first_custom_label {
      @media only screen and (max-width: 500px) {
        margin-top: 0px !important;
        margin-bottom: 0px !important;
      }
    }

    p {
      &:not(._custom_label) {
        font-size: 14px;
        color: #1c1634;
      }
    }
  }
  @media only screen and (max-width: 500px) {
    .left-part-container {
      padding-bottom: 0px !important;
    }
  }
  .wrapper_contribution_details{
    .custom_prayer_contribution{
      @media only screen and (max-width: 500px){
        margin-bottom: 36px!important;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .navbar-toggler {
    padding-right: 0.25rem !important;
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .step3-index {
    .wrapper_request_details,
    .wrapper_contribution_details,
    .gift_message_wrapper {
      margin-bottom: 16px !important;
    }
  }
}
