@import "@iso/assets/variables.scss";

.bounced-email-modal {
  @media only screen and (max-width: 500px){
    padding: 0px 35px
  }
  .bounced-email-input {
    width: 100%;
    height: 40px;
    border: 1px solid $blueSky_color;
    border-radius: 3px;
    padding: 10px;
    padding-left: 20px;
    background: #f7f8f9;
    color: #32325d;
    font-size: 16px;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  .bounced-email-input:-webkit-autofill {
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    background-color: transparent;
  }
  .bounced-email-input:focus {
    outline: none;
  }

  .bounce-email-title {
    margin-top: 80px;
    font-size: 36px;
    font-weight: bold;
    text-align: center;
    line-height: 64px;
    @media only screen and (max-width: 500px){
      font-size: 25px;
      margin-top: 35px;
    }
    @media only screen and (max-width: 300px){
      font-size: 18px;
    }
  }
  .form-container {
    max-width: 360px;
    margin: auto;
    margin-top: 25px;
  }
  .hippie-blue-button {
    margin-top: 26px;
    width: 100%;
    margin-bottom: 77px;
  }
  .input-group {
    margin-bottom: 14px;
  }
  .error-msg {
    color: #de4b4b;
    font-size: 14px;
  }
  .result-msg {
    text-align: center;
    font-size: 18px;
  }
}
