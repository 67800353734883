@import "@iso/assets/variables.scss";
.request_item_wrapper{
  .link-hippie{
    color: $hippie-blue-color;
    text-decoration: underline ;
  }

  .link-blue-light{
    color:$blue_light_color; 
  }
  
}