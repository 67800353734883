.prayer-parner-section-4 {
  #heart {
    margin-left: 24px;
    @media only screen and (min-width: 1024px) {
      width: 221px;
      height: 93px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
        height: 80px;
        width: 190px;
    }
    @media only screen and (max-width: 500px) {
        height: 75px;
        width: 178px;
        margin-left: -20px;
    }
  }

  @media only screen and (min-width: 768px) {
    #heart {
      margin-left: 24px;
    }
    .group-heart {
      margin-top: 30px;
    }
  }

  .group-heart {
    display: inline-flex;
    align-items: end;
  }

  @media only screen and (max-width: 500px) {
    .group-heart {
      display: block;
    }
    .heart-text-2 {
      margin-top: 30px;
    }
  }

  .sub-group-heart {
    display: inline-flex;
    align-items: end ;
  }
}
