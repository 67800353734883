@import "@iso/assets/variables.scss";
.CustomizationDetails {
  // Fix outline on datepicker button
  .MuiButtonBase-root.MuiIconButton-root {
    outline: none !important;
  }

  @media only screen and (min-width: 1025px) {
    padding_left_label: 0px;
  }

  .MuiSelect-select:focus {
    background: none;
  }
  // Remove tick on input value
  .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-filled.MuiInputBase-input.MuiInput-input {
    svg {
      display: none;
    }
  }
  .picker-wrapper {
    .MuiInput-underline:before {
      border-bottom: 2px solid #499e9e !important;
    }

    .MuiInput-underline {
      &.custom-prayertypes:before {
        margin-left: 0 !important;
      }
    }
  }

  .picker-wrapper .MuiInputAdornment-positionStart {
    margin-right: -12px;
    .MuiSvgIcon-root {
      display: inline-block !important;
    }
  }
  .picker-wrapper > .MuiSvgIcon-root {
    position: absolute;
    right: -15px;
    top: 15px;
    cursor: pointer;
    @media only screen and (max-width: 768px) {
      right: 20px;
    }
  }
  .picker-wrapper > .MuiTooltip-popper,
  #left-part-wrapper .MuiTooltip-popper {
    max-width: 218px;
    font-style: italic;
    .MuiTooltip-tooltip {
      font-size: 14px;
      padding-left: 10px;
    }
    .tooltip-wrap {
      font-size: 16px;
      font-family: BrandonText;
      padding: 3px 0px;

      .tooltip-message {
        border-left: 4px solid #499e9e;
        border-radius: 2px;
        padding-left: 10px;
      }
    }
    > div {
      background: #d9e9f1;
      color: black;
    }
    .MuiTooltip-arrow {
      color: #d9e9f1;
    }
  }

  .pray-item {
    display: inline-block;
    border: 1px solid $hippie-blue-color;
    margin-right: 7px;
    border-radius: 4px;
    padding: 2px 8px;
    margin-bottom: 10px;
    cursor: pointer;
    &.active {
      border: 1px solid $hippie-blue-color;
      color: #ffffff;
      background: $hippie-blue-color;
    }
  }

  .see-more-link {
    color: $hippie-blue-color;
    white-space: nowrap;
  }

  @media only screen and (max-width: 500px) {
    .step3-customization-input {
      padding-left: 5px !important;
    }
  }
  .MuiSelect-filled.MuiSelect-filled {
    @media only screen and (min-width: 1025px) {
      padding-bottom: 8px;
      padding-left: 30px;
    }
  }
  .width_calendar_custom {
    @media only screen and (min-width: 1025px) {
      max-width: 220px;
      padding: 0;
      margin-left: -15px;
      margin-right: 15px;
      input {
        padding-left: 55px !important;
      }
      &.bg_lighter_hippie_blue_custom {
        padding-top: 0px;
        .MuiInput-underline:before {
          width: 100%;
        }
      }
      &.bg_lighter_hippie_blue {
        padding-top: 5px;
        .MuiInput-underline:before {
          margin-left: 25px;
          width: 100%;
        }
      }
      .padding_custom_label {
        @media only screen and (min-width: 1025px) {
          padding-left: 30px;
        }
      }
    }
    .padding_custom_label {
      @media only screen and (max-width: 500px) {
        margin-bottom: 0.5rem !important;
        padding-top: 5px !important;
      }
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      .MuiInput-underline:before {
        width: 291px;
        margin-left: -5px;
      }
    }

    @media only screen and (max-width: 767px) {
      margin-bottom: 0;
    }

    @media only screen and (max-width: 500px) {
      margin-bottom: 17px;
    }
  }
  .denomination_width_custom {
    @media only screen and (min-width: 1025px) {
      // max-width: 240px !important;
      margin-left: 4px;
      padding-left: 0;
      &.bg_lighter_hippie_blue {
        padding-top: 5px;
      }
    }
    .padding_custom_label {
      @media only screen and (min-width: 1025px) {
        padding-left: 30px;
      }
      @media only screen and (max-width: 500px) {
        margin-bottom: 0.5rem !important;
        padding-top: 5px;
      }
    }
    .MuiInput-underline:before {
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        width: 291px;
        margin-left: -5px;
      }
    }
    @media only screen and (max-width: 376px) {
      margin-bottom: 17px;
    }
    @media only screen and (min-width: 377px) and (max-width: 500px) {
      margin-bottom: 17px;
    }
  }
  .custom_padding_right_edit_mode {
    @media only screen and (min-width: 1025px) {
      padding-right: 0 !important;
    }
  }
  .padding_top_custom_edit_mode {
    @media only screen and (min-width: 1025px) {
      padding-top: 0;
    }
  }
  .padding_right_handle_click {
    @media only screen and (min-width: 1025px) {
      padding-right: 60px !important;
    }
  }
  .margin_bottom_form_group {
    @media only screen and (min-width: 1025px) {
      // margin-bottom: 2rem !important;
      margin-bottom: 12px !important;
      min-width: 522px;
    }
  }
  .padding_left_special_request {
    @media only screen and (max-width: 500px) {
      margin-bottom: 15px;
    }
    @media only screen and (min-width: 1025px) {
      #special_request-label {
        font-family: "BrandonText";
        font-size: 14px;
        color: #6d7278;
        padding-left: 45px;
      }
      padding-left: 0px !important;
      padding-right: 0px !important;
      max-width: 500px !important;
      margin-left: -7px !important;
      margin-bottom: 10px;
      margin-top: 0 !important;

      textarea {
        width: 449px;
        padding-left: 45px !important;
      }
      &.bg_lighter_hippie_blue {
        #special_request-label {
          font-family: "BrandonText";
          padding-left: 25px;
          color: #6d7278;
        }
        textarea {
          padding-top: 5px;
          padding-left: 25px !important;
        }
      }
    }
    .margin_top_special_request_edit_mode {
      @media only screen and (min-width: 1025px) {
        margin-top: 0 !important;
        padding-top: 0 !important;
      }
      @media only screen and (max-width: 376px) {
        width: 315px;
        margin-left: -6px !important;
        .MuiInput-underline:before {
          margin-left: -10px !important;
        }
        label {
          padding-left: 15px !important;
        }
        textarea {
          padding-left: 5px !important;
        }
      }
      @media only screen and (min-width: 377px) and (max-width: 500px) {
        width: 353px;
        margin-left: -6px !important;
        .MuiInput-underline:before {
          margin-left: -10px !important;
        }
        label {
          padding-left: 15px !important;
        }
        textarea {
          padding-left: 5px !important;
        }
      }
    }
  }
  .custom_form_most_request_prayer {
    @media only screen and (min-width: 1025px) {
      margin-top: 25px !important;
      padding-right: 14px;
      margin-left: -30px;
      width: 520px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      margin-bottom: 26px !important;
    }
    @media only screen and (max-width: 500px) {
      &.form-group {
        margin-bottom: 0px !important;
      }
    }
  }
  .width_most_request_prayer {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      .MuiInput-underline:before {
        width: 291px;
        margin-left: -5px;
        border-bottom: 2px solid #499e9e;
      }
    }
    @media only screen and (max-width: 376px) {
      .form-group {
        margin-bottom: 0px !important;
      }
      margin-bottom: 17px;
    }
    @media only screen and (min-width: 377px) and (max-width: 500px) {
      margin-bottom: 17px;
    }
  }
  .custom_height_prayer_date {
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      height: 60px;
      margin-bottom: 23px;
      input {
        padding-left: 0px !important;
      }
      .MuiSelect-select {
        padding-top: 8px;
      }
    }
  }
  .padding_handle_click {
    @media only screen and (min-width: 1025px) {
      padding-right: 30px;
    }
  }
  .custom_customization_title {
    @media only screen and (min-width: 1025px) {
      padding-left: 15px;
    }
  }
  .margin_custom_card_wrapper {
    @media only screen and (min-width: 1025px) {
      margin-top: 40px;
    }
  }
  .custom_special_request_not_edit {
    @media only screen and (min-width: 1025px) {
      margin-top: 5px !important;
    }
  }
  .margin_top_special_request_not_focus {
    @media only screen and (min-width: 1025px) {
      margin-top: 18px;
      padding-right: 30px;
    }
  }
  .custom_special_request_not_edit {
    @media only screen and (min-width: 1025px) {
      padding-right: 40px;
    }
  }
  .custom_religion_is_not_edit.most_request_group {
    @media only screen and (max-width: 500px) {
      margin-top: 18px !important;
    }
  }
  .custom_religion_is_not_edit{
    @media only screen and (max-width: 500px) {
      margin-top: 35px;
    }
  }
  .custom_most_request_prayer_is_not_edit {
    @media only screen and (max-width: 500px) {
      margin-top: 35px;
    }
  }
  .custom_special_request_not_edit {
    @media only screen and (max-width: 500px) {
      margin-top: 18px;
      margin-bottom: 15px;
    }
  }
  .margin_top_special_request_edit_mode {
    .MuiInput-formControl {
      @media only screen and (max-width: 500px) {
        margin-top: 0px !important;
      }
    }
  }
  .margin_custom_card_wrapper_not_edit {
    @media only screen and (min-width: 1025px) {
      margin-top: 16px;
    }
  }
  .custom_label_color_hover {
    label {
      color: #499e9e !important;
    }
  }
}
.calendarContainer {
  .border_custom_calendar {
    @media only screen and (min-width: 1025px) {
      border: 1px solid #499e9e;
      padding: 10px;
      border-radius: 6px;
      padding: 20px;
      margin: -15px;
    }
  }
}
.border_selected_custom {
  @media only screen and (min-width: 1025px) {
    .MuiMenu-paper {
      border: 1px solid #499e9e;
      width: 292px;
      border-radius: 6px;
    }
    .MuiListItem-gutters {
      padding-left: 24px !important;
    }
  }
}
