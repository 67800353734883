#mobileHeader-container {
  .MuiBackdrop-root {
    background-color: #e5e5e5 !important;
  }

  .prayerCloudLogo {
    border-bottom: 1px solid #ECF4F8;
    padding-left: 8px;
  }

  .prayer-hippe-button {
    width: 170px;
    height: 37px;
    margin-left: -10px;
    position: relative;

    .hippie-btn-text {
      position: absolute;
      left: 25px;
      top: 5px;
    }
  }
}

//@media (min-width: 1440px) and (max-width: 1922px) {
//  .public-header {
//    padding-left: calc((100vw - 1440px)/2) !important;
//    padding-right: calc((100vw - 1440px)/2) !important;
//  }
//}

@media (min-width: 1922px) {
  .public-header {
    padding-left: (1922 - 1440px)/2 !important;
    padding-right: (1922 - 1440px)/2 !important;
  }
}

@media (min-width: 767px) and (max-width: 1024px) {
  .prayer-tablet-header {
    padding-left: 32px !important;
    padding-right: 32px !important;

    .navbar-nav {
      &.header-tablet {
        a {
          &.nav-link {
            padding-right: 18px;
            padding-left: 22px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 500px){
  .prayer-tablet-header{
    padding-left: 25px!important;
  }
}
