.payment_benefit_details_wrapper {
  .table_wrapper {
    height: calc(100% + 100px);
    min-height: calc(100vh + 100px);
    position: relative;

    .notes_bottom {
      bottom: 10px;
    }

    thead {
      border-top: 2px solid #000;
    }
    tbody {
      border-bottom: 2px solid #000;
    }
    table,
    thead,
    tbody,
    th,
    td {
      border-left: 2px solid #000;
      border-right: 2px solid #000;
    }
  }
}
