.tablet-value-container {
  padding: 0 39px !important;
}

.tablet-item{
  flex-basis: unset !important;
  max-width: 210px;

  &:not(:first-child) {
    margin-left: 33px;
  }
}
@media only screen and (min-width: 376px) and (max-width: 500px){
  .custom_grid_section_3{
    padding-top: 83px!important;
  }
}