.account_settings.earning_percentage {
  padding-right: 10px;
  .input_percentage::after {
    content: "%";
    position: absolute;
    right: -20px;
    font-weight: 500;
    top: 5px;
  }

  p.Mui-error {
    color: #f44336 !important;
    font-size: 12px !important;
  }
  @media only screen and (max-width: 500px){
    .card_info_control{
      width: 316px;
      margin-left: -4px!important;
    }
    .custom_title-earning{
      margin-bottom: 15px!important;
    }
    .custom_material_input{
      margin-bottom: 15px;
    }
  }
}
