@import "@iso/assets/variables.scss";

.howItWorks__carousel{
    width: 100%;
    height: 280px;
    .carousel-indicators {
      li {
        width: 8px;
        height: 8px;
        border: 1px solid $hippie-blue-color;
        border-radius: 50%; 
        margin-top: 40px;


        &:not(:first-child){
          margin-left: 16px;
        }

        &.active{
          background: $hippie-blue-color;
          border: 1px solid $hippie-blue-color;
        }
      }
      @media only screen and (min-width: 376px) and (max-width: 500px){
        bottom: -27px;
      }
    }
}