
.account_page__change_password {
  .my-control {
    .MuiFormLabel-root {
      font-family: BrandonText !important;
      padding-left: 10px;
      color: #000000;
      .for-focus {
        display: none;
      }
      &.Mui-focused {
        color: rgba(0, 0, 0, 0.4);
        .for-focus {
          display: unset;
        }
        .for-no-focus {
          display: none;
        }
      }
    }

    .MuiInputLabel-shrink{
      color : rgba(0, 0, 0, 0.4);
      font-size: 14px;
      transform: translate(9px, 10px) scale(1);
    }

    .MuiFilledInput-root {
      background: none !important;
      border: 1px solid #ced4da !important;
      border-radius: 6px;
      padding-left: 7px;
    }

    .MuiFilledInput-underline:before, .MuiFilledInput-underline:after {
      border-bottom: none !important;
    }

    .MuiFormHelperText-root{ 
      margin-left: 0px;
      font-style: italic;
      font-family: BrandonText;
      font-size: 14px;
      color: #D45252;
    }
  }

  .active-Form{
    .MuiFilledInput-root:focus-within{
      border: 1px solid #52B9D4!important;
    }
  }

  .psd_confirm{
    .MuiFormHelperText-root{
      margin-top: -81px;
      margin-bottom: 60px;
    }
  }
  
  .border-errors{
    .MuiFilledInput-root {
      border: 1px solid #D45252!important;
      border-radius: 6px;
    }
    // .MuiFormLabel-root.Mui-error {
    //   color: rgba(0, 0, 0, 0.54)!important;
    // }
  }
  .save-password-buttons {
    padding: 0;
  }
  .hover_pointer {
    font-size: 18px !important;
  }
  
}