@import "@iso/assets/variables";

.request_flagged_builder_container {
  background-color: $lighter-gray-color;
  border-radius: 5px;
}

@media only screen and (max-width: 600px) {
  .request_flagged_builder_container {
  height: 280px;
}
}
