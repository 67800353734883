.modal_toggle_wrapper {
  .checkbox_for_flag {
    .MuiFormControlLabel-root {
      margin-bottom: 0;
    }
    .MuiTypography-body1 {
      font-family: unset;
    }
  }
  .MuiFormControlLabel-root{
    margin-right: 20px;
  }
}
