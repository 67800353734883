.feedback-card {
  .group-1 {
    display: inline-flex;
    p {
      line-height: 34px;
      color: #1c1634;
      margin-bottom: -10px;
    }
    .name {
      font-weight: bold;
      font-size: 24px;
    }
    .address {
      font-weight: 390;
      font-size: 20px;
    }
    .denomination {
      font-weight: 330;
      font-size: 17px;
      font-size: 17px;
      letter-spacing: -0.5px;
    }
    img {
      @media only screen and (max-width: 500px) {
        width: 95px;
        height: 95px;
      }
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        width: 111px;
        height: 111px;
      }
      @media only screen and (min-width: 1024px) {
        width: 116px;
        height: 116px;;
      }
    }
  }
  .introduction {
    font-size: 15px;
    margin-left: 20px;
    line-height: 18px;
    @media only screen and (max-width: 500px) {
      margin-top: 10px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px) {
      margin-top: 7px;
    }
  }
  .feedback-content {
    font-size: 15px;
    line-height: 18px;
    .text-highlight{
      font-weight: bold;
      background-color: rgba(175, 218, 245, 0.3);
      padding: 0px 2px
    }
  }
  .group-2 {
    margin-top: 16px;
    margin-left: 16px;
    width: 146px;
    @media only screen and (max-width: 500px) {
      margin-left: 23px;
    }
  }
  .feedback {
    position: relative;
    width: 280px;
    .double-quotation {
      position: absolute;
      top: -6px;
      left: -46px;
    }
    .right-cloud {
      position: absolute;
      right: -25px;
      top: -25px;
    }
    .position-right-cloud-alex {
      position: absolute;
      right: 58px;
      top: -25px;
      @media only screen and (max-width: 500px) {
        right: -25px;
        top: -25px;
      }
      @media only screen and (min-width: 768px) and (max-width: 1024px) {
        right: 64px;
        top: -35px;
      }
    }
    .left-cloud {
      position: absolute;
      left: -25px;
      bottom: -36px;
    }
    .position-left-cloud-alex {
      position: absolute;
      left: -25px;
      bottom: -65px;
      @media only screen and (max-width: 500px) {
        bottom: -36px;        
      }
      @media only screen and (min-width: 768px) and (max-width: 1023px) {
        bottom: -73px;
      }
      @media only screen and (min-width: 1023px) {
        bottom: -73px;
      }
    }
  }
}

@media only screen and (max-width: 500px) {
  .feedback-card {
    margin-bottom: 39px;
    .feedback {
      margin-top: 20px;
      .double-quotation {
        top: -10px;
        left: -36px;
      }
    }
  }
}

@media only screen and (min-width: 500px) {
  .feedback-content{
    margin-top: 23px;
  }
}

@media only screen and (min-width: 500px) and (max-width: 1024px) {
  .feedback-card {
    margin-bottom: 85px;
  }
}

@media only screen and (min-width: 1025px) {
  .feedback-card{
    .feedback{
      margin-left: 22px;
    }
  }
}