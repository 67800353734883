.mobile-menu,
.account_page {
  font-family: BrandonText !important;
  &__wrapper {
    font-family: "BrandonText";
    padding: 40px 150px;
    background-color: #f5f9fb;

    @media only screen and (max-width: 600px) {
      padding: 26px 0px;
    }

    @media only screen and (min-width: 768px) and (max-width: 900px) {
      padding: 39px;
    }
  }

  &__title {
    font-size: 46px;
    font-weight: bold;
    margin-bottom: 37px;

    @media only screen and (max-width: 990px) {
      display: none;
    }
  }

  &__tabs_wrapper {
    background: #ffffff;
    border-radius: 6px;

    .makeStyles-menuActive-8 {
      .MuiTab-wrapper {
        color: #52b9d4 !important;
      }
    }

    .MuiAccordionSummary-content.Mui-expanded {
      .MuiTypography-root.MuiTypography-body1 {
        color: #52b9d4;
      }
    }

    .Mui-expanded {
      min-height: 0px !important;
    }

    // .MuiTab-wrapper {
    //   color: #000000;
    // }
    // Header
    .MuiCardHeader-root {
      height: 121px;
      padding: 28px 40px;
      border-bottom: 1px solid #d9e9f1!important;
      display: flex;
      align-items: center;
      @media only screen and (max-width: 500px) {
        height: 78px;
        text-align: center;
        padding: 30px;
      }
    }

    .MuiTypography-root.MuiCardHeader-title.MuiTypography-h5.MuiTypography-displayBlock {
      font-family: "BrandonText";
      font-size: 36px;
      font-weight: bold;
      @media only screen and (max-width: 500px) {
        font-size: 30px;
      }
    }

    // Subtabs style
    .MuiPaper-root.MuiAccordion-root.makeStyles-accordionRoot-3 {
      .MuiAccordionSummary-root.Mui-expanded {
        min-height: 60px;
      }

      .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit {
        font-family: "BrandonText";
        font-size: 16px;
        line-height: 34px;
        text-transform: none;
        min-width: 100%;
        outline: none;
        display: block;

        height: 38px;

        span {
          align-items: flex-start;
          padding-left: 94px;
        }

        &.Mui-selected {
          font-weight: bold;
          background: #ecf4f8;
          box-shadow: 0px 1px 0px #ecf4f8;
        }
      }
    }

    // Tab Buttons
    .MuiTabs-flexContainer.MuiTabs-flexContainerVertical {
      .MuiButtonBase-root.MuiTab-root.MuiTab-textColorInherit {
        font-family: "BrandonText";
        font-size: 18px;
        line-height: 34px;
        text-transform: none;
        min-width: 100%;
        outline: none;
        display: block;
        opacity: 1;
        height: 60px;
        border-bottom: 1px solid #d9e9f1;

        span {
          align-items: flex-start;
          padding-left: 78px;
        }
        @media only screen and (min-width: 768px) and (max-width: 1024px) {
          span {
            padding-left: 40px;
          }
        }
      }
    }
  }

  &__profile {
    .MuiCardHeader-action {
      align-self: center;
    }

    .info-header {
      .MuiCardHeader-content {
        height: 58px;
        @media only screen and (max-width: 500px) {
          height: auto;
        }
      }
    }

    .my-control {
      .MuiFormLabel-root {
        font-family: BrandonText !important;
        color: rgba(0, 0, 0, 0.4);

        .for-focus {
          display: none;
        }
        &.Mui-focused {
          // color: unset !important;

          .for-focus {
            display: unset;
          }
          .for-no-focus {
            display: none;
          }
        }
      }
      .MuiFilledInput-root {
        background: none !important;
        border: 1px solid #ced4da !important;
        border-radius: 6px;
      }

      .MuiFilledInput-root:focus-within {
        border: 1px solid #52b9d4 !important;
      }

      .MuiFilledInput-underline:before,
      .MuiFilledInput-underline:after {
        border-bottom: none !important;
      }
    }

    .profile_info__form {
      font-family: sans-serif!important;
      .MuiFilledInput-input {
        font-family: sans-serif;
        font-size: 18px;
      }

      .my-picker {
        input {
          font-family: "BrandonText";
          font-size: 18px;
          padding-bottom: 10px;
          padding-top: 28.5px;
        }
      }

      .view {
        border: none !important;
        color: black !important;
        input {
          -webkit-appearance: none;
          border: none !important;
          color: #000000;
        }

        .MuiInputBase-root {
          &.Mui-disabled {
            color: #000000!important;
          }
        }
        .MuiInputBase-root {
          border: none !important;
        }

        fieldset.MuiOutlinedInput-notchedOutline {
          border: none !important;
        }

        .MuiInputAdornment-root {
          display: none !important;
        }

        &.my-control2 {
          border: none !important;

          svg {
            display: none !important;
          }
        }
      }
      @media only screen and (min-width: 768px){
        font-family: "BrandonText"!important;
        font-size: 18px;
      }
    }

    .button {
      &__save {
        font-size: 16px;
        height: 48px;
        width: 90px;
        padding: 12px 8px;

        @media only screen and (max-width: 800px) {
          font-size: 16px;
        }
      }

      &__cancel {
        font-family: "BrandonText";
        color: rgba(242, 108, 81, 1);
        font-size: 20px;

        @media only screen and (max-width: 350px) {
          margin-right: 0px !important;
        }

        @media only screen and (max-width: 500px) {
          margin-right: 16px !important;
        }

        @media only screen and (max-width: 800px) {
          font-size: 16px;
        }

        &:hover {
          cursor: pointer;
        }
      }
    }

    .avatar {
      width: 100px;
      height: 100px;

      @media only screen and (min-width: 900px) {
        width: 157px;
        height: 157px;
        margin-top: 40px;
      }
    }

    .my-control {
      .MuiFormLabel-root {
        font-family: BrandonText !important;
        .for-focus {
          display: none;
        }
        &.Mui-focused {
          // color: unset !important;

          .for-focus {
            display: unset;
          }
          .for-no-focus {
            display: none;
          }
        }
      }
      .MuiFilledInput-root {
        background: none !important;
        border: 1px solid #ced4da !important;
        border-radius: 6px;
      }

      .MuiFilledInput-root:focus-within {
        border: 1px solid #52b9d4 !important;
      }

      .MuiFilledInput-underline:before,
      .MuiFilledInput-underline:after {
        border-bottom: none !important;
      }
    }

    .profile_info__form {
      .custom-label {
        font-family: "BrandonText";
        top: 11px;
        left: 15px;
      }

      .account-form-label, .MuiInputLabel-formControl.Mui-disabled {
        color: rgba(0, 0, 0, 0.4)
      }

      @media only screen and (min-width: 990px) {
        margin-top: 40px;
        margin-bottom: 120px;
      }

      .my-picker {
        &::placeholder {
          font-family: "BrandonText" !important;
        }

        .MuiOutlinedInput-notchedOutline {
          color: rgba(0, 0, 0, 0.87) !important;
          border: 1px solid #ced4da !important;
        }
      }

      .MuiSelect-filled.MuiSelect-filled {
        padding: 18px 16px;
        border-radius: 6px;
      }

      .my-control2 {
        background: transparent;
        border: 1px solid #ced4da !important;
        transition: unset !important;
        box-shadow: none !important;
        border-radius: 6px;

        .MuiSelect-nativeInput {
          &::-webkit-input-placeholder {
            /* Edge */
            font-family: "BrandonText" !important;
          }

          &:-ms-input-placeholder {
            /* Internet Explorer 10-11 */
            font-family: "BrandonText" !important;
          }

          &::placeholder {
            font-family: "BrandonText" !important;
          }
        }

        &.form-control {
          &:focus {
            background: transparent;
          }
        }

        .MuiFilledInput-root {
          background: none !important;
          border-radius: 6px;
        }
        .MuiFilledInput-underline:before,
        .MuiFilledInput-underline:after {
          border-bottom: none !important;
        }

        .MuiSelect-filled.MuiSelect-filled {
          font-family: "BrandonText";
          font-size: 18px;
          padding: 18px 14px;
          padding-bottom: 10px;
          padding-top: 28.5px;
          padding-right: 42px;
          color: #000000;
        }
        .MuiSvgIcon-root {
          right: 24px;
        }
        &.MuiInput-underline:before {
          border-bottom: none;
        }
        &.MuiInput-underline:after {
          border-bottom: none;
        }

        &.MuiInput-underline:hover:not(.Mui-disabled):before {
          border-bottom: none;
        }

        &.Mui-focused {
          border: 1px solid #52b9d4 !important;
        }
        .MuiSelect-root .MuiSvgIcon-root {
          display: none;
        }
        .MuiSelect-select:focus {
          background-color: transparent;
        }
      }
    }
  }

  &__my_prayers {
    .MuiCardHeader-action {
      align-self: center;
    }

    .my-control2.sort-select {
      height: 48px;
      width: 120px;
      padding: 13px 15px;
      background: transparent;
      border: 1px solid #ced4da !important;
      transition: unset !important;
      box-shadow: none !important;
      border-radius: 6px;
      font-family: "BrandonText" !important;
      margin-left: 22px;

      svg {
        padding-right: 5px;
      }
    }

    &__wrapper {
      // GENERAL INPUT VALUES
      .MuiOutlinedInput-inputAdornedEnd {
        font-family: "BrandonText";
      }
      .MuiSelect-root.MuiSelect-select.MuiSelect-selectMenu.MuiSelect-filled.MuiInputBase-input.MuiInput-input {
        font-family: "BrandonText";
      }
      input {
        &::-webkit-input-placeholder {
          font-family: "BrandonText";
          color: rgba(0, 0, 0, 1);
          font-weight: normal;
        }

        &::-moz-placeholder {
          font-family: "BrandonText";
          color: rgba(0, 0, 0, 1);
          font-weight: normal;
        }

        &::-ms-placeholder {
          font-family: "BrandonText";
          color: rgba(0, 0, 0, 1);
          font-weight: normal;
        }

        &::placeholder {
          font-family: "BrandonText";
          color: rgba(0, 0, 0, 1);
          font-weight: normal;
        }
      }
    }
  }
}
