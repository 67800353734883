.add_record_wrapper{ 
  .modal-header{
    border:none;

    .modal-title{
      width: 100%;
      text-align: center;
      margin-top: 19px;
    }
  }

  .modal-body{
    padding-left: 55px!important;
    padding-right: 55px!important;
  }
  
  .MuiSelect-filled.MuiSelect-filled:focus{
    background-color: white;
  }

  .hippie-blue-button{
    width: 100px;
  }

  .currencyTextField{
    .MuiInputBase-input{
      margin-left: -7px;
    }
    .MuiTypography-colorTextSecondary{
      color: black;
    }
  }

  #prayer_request_id{
    .Mui-focused {
      outline: none!important; 
      box-shadow: none!important;
      border: none!important;
    }
  }
}