@import "bootstrap/scss/bootstrap.scss";

.share-your-request-landing {
  .action-container {
    .share-button {
      width: 56px;
      height: 56px;

      &:not(:first-child) {
        margin-left: 42px;
      }

      @media only screen and (max-width: 900px) {
        width: 44px;
        height: 44px;
      }

      box-shadow: inset 0px -1px 0px rgba(0, 0, 0, 0.2);

      &:focus {
        outline: none;
      }

      &:active {
        background-color: #398787 !important;
      }
    }
  }
}
