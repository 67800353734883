.MuiAlert-message {
  width: 100%;
  text-align: center;
  font-family: "BrandonText";
}

.MuiAlert-action {
  position: absolute;
  right: 60px;
  margin-left: 0px;
  margin-right: 0px;
}
.alert-desktop {
  @media (min-width: 1440px) {
    top: -77vh !important;
  }
}
