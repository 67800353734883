@import "@iso/assets/variables.scss";
.stripe_card_session, .coupon-code-container {
  .custom_element_stripe {
    margin-top: 15px;
    padding: 10px;
    border: 1px solid $blueSky_color;
    border-radius: 3px;
    background: #F7F8F9;  
    @media only screen and (max-width: 500px ){
      margin-top: 30px;
    }
  }
  .margin_top_stripe{
    margin-top: 15px;
    @media only screen and (max-width: 1024px){
      margin-top: 30px!important;
    }
  }
  .stripe_card_session{
    @media only screen and (max-width: 500px){
      margin-top: 30px!important;
    }
  }
  .custom_input_stripe {
    width: 100%;
    height: 40px;
    border: 1px solid $blueSky_color;
    border-radius: 3px;
    padding: 10px;
    padding-left: 20px;
    background: #F7F8F9;
    color: #32325d;  
    font-size: 16px;
    font-family: "Helvetica Neue", Helvetica, sans-serif;
    -webkit-font-smoothing: antialiased;
    @media only screen and (max-width: 500px){
      padding-left: 0;
      padding: 10px;
    }
  }

  .custom_input_stripe:-webkit-autofill{
    -webkit-box-shadow: 0 0 0px 1000px white inset;
    background-color: transparent;  
  }

  .StripeElement--focus,
  .custom_input_stripe:focus{
    outline: none; 
  } 

  .error-border{
    border: 1px solid red!important;
  }

  .custom-control-input:checked ~ .custom-control-label::before,
  .custom-checkbox
    .custom-control-input:disabled:checked
    ~ .custom-control-label::before {
    background-color: #499e9e;
    border: 1px solid #499e9e;
  }

  .save_card{ 
    margin-bottom: -10px;
    label{
      font-size: 18px;
      color: #1c1634;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px){
      margin-top: 30px;
    }
    @media only screen and (max-width: 500px){
      margin-top: 40px;
    }
    @media only screen and (min-width: 1025px){
      margin-top: 20px;
    }
  }

  .save_card.custom-checkbox .custom-control-input:checked ~ .custom-control-label::after{
    top: 0px;
  }
  @media only screen and (max-width: 376px){
    .custom_input_element_stripe_expiry{
      padding-right: 3px;
    }
    .custom_input_element_stripe_cvc{
      padding-right: 11px;
      padding-left: 11px;
    }
    .custom_postal_stripe{
      padding-left: 3px;
      margin-top: 30px;

    }
  }
  @media only screen and (min-width: 376px) and (max-width: 500px){
    .custom_input_element_stripe_expiry{
      padding-right: 3px;
    }
    .custom_input_element_stripe_cvc{
      padding-right: 11px;
      padding-left: 11px;
    }
    .custom_postal_stripe{
      padding-left: 3px;
      margin-top: 30px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .custom_postal_stripe{
      margin-top: 15px!important;
    }
  }
  @media only screen and (min-width: 1024px){
    .custom_postal_stripe{
      margin-top: 15px!important;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 1024px){
    .custom_input_element_stripe_expiry{
      .custom_element_stripe{
        width: 100%!important;
      }
      padding-right: 7px!important;
    }
    .custom_input_element_stripe_cvc{
      padding-right: 9px!important;
      padding-left: 9px;
      .custom_element_stripe{
        width: 100%!important;
      }
    }
    .custom_postal_stripe{
      padding-left: 7px;
    }
    .custom_stripe_name_on_card{
      input#name{
        -webkit-appearance: none!important;
        box-shadow: none!important;
        -moz-appearance: none!important;
        appearance: none!important;
      }
    }
    .custom_postal_stripe{
      input#postal{
        -webkit-appearance: none!important;
        box-shadow: none!important;
        -moz-appearance: none!important;
        appearance: none!important;
      }
    }
  }
  @media only screen and (max-width: 500px){
    .custom_stripe_name_on_card{
      input#name{
        -webkit-appearance: none!important;
        box-shadow: none!important;
        -moz-appearance: none!important;
        appearance: none!important;
      }
    }
    .custom_postal_stripe{
      input#postal{
        -webkit-appearance: none!important;
        box-shadow: none!important;
        -moz-appearance: none!important;
        appearance: none!important;
      }
    }
    .custom_input_element_stripe_expiry{
      .custom_element_stripe{
        padding-left: 9px!important;
      }
    }
    .custom_postal_stripe{
      .custom_input_stripe{
        padding-left: 8px;
      }
    }
  }
}

.custom_payment_method_wrapper{
  .custom_stripe_card_session{
    @media only screen and (max-width: 376px){
      padding-left: 30px;
      padding-right: 30px;
    }
    @media only screen and (min-width: 377px) and (max-width: 500px){
      padding-left: 30px;
      padding-right: 30px;
    }
  }
  @media only screen and (max-width: 500px){
    .custom_stripe_name_on_card{
      margin-bottom: -0.5rem;
      input#name{
        -webkit-appearance: none!important;
        box-shadow: none!important;
        -moz-appearance: none!important;
        appearance: none!important;
      }
    }
    .custom_postal_stripe{
      input#postal{
        -webkit-appearance: none!important;
        box-shadow: none!important;
        -moz-appearance: none!important;
        appearance: none!important;
      }
    }
    .custom_stripe_card_number{
      margin-bottom: -0.5rem;
    }
    .custom_stripe_element_mini{
      margin-bottom: -0.5rem;
    }
  }
}
