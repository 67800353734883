@import "@iso/assets/variables.scss";
#select-payment-wrapper{
    padding: 20px 25px;
    padding-bottom: 0px;
    border: 1px solid #afdaf5;
    border-radius: 6px;
    box-shadow: 0px 15px 18px rgba(0, 0, 0, 0.05);
    margin-bottom: 13px;
    @media only screen and (max-width: 376px){
      margin: auto auto 13px auto;
      max-width: 330px;
      height: auto;
      padding: 0px;
    }
    @media only screen and (min-width: 377px) and (max-width: 500px){
      margin: auto auto 13px auto;
      max-width: 365px;
      height: auto;
      padding: 0px;
    }
    @media only screen and (min-width: 768px) and (max-width: 1024px){
      .power-stripe-container{
        margin-top: 75px!important;
        margin-bottom: 40px!important;
      }
    }
  #cardWrapper{
    @media only screen and (max-width: 376px){
      border-bottom: 1px solid #afdaf5 ;
      height: auto;
      border-radius: 6px;
      padding: 25px 25px 10px 25px;
      .custom_payment_method_title{
        margin-left: -12px;
        margin-right: -15px;
      }
    }
    @media only screen and (min-width: 377px) and (max-width: 500px){
      border-bottom: 1px solid #afdaf5 ;
      height: auto;
      border-radius: 6px;
      padding: 25px 25px 10px 25px;
      .custom_payment_method_title{
        margin-left: -12px;
        margin-right: -15px;
      }
    }
  }
  .paymentMethodTitle{
    font-weight: 600;
    color: #1C1634;
    font-size: 18px;
  } 

  .hippie-btn{
    color: $hippie-blue-color; 
    cursor: pointer;
  }

  .custom-radio.custom-control{ 
    padding-left: 35px;
  }

  .custom-radio{
    .custom-control-label::before, 
    .custom-control-label::after {
      top: 2px; 
      width: 23px;
      height: 23px;
    }

    .custom-control-input:checked ~ .custom-control-label::after{ 
      width: 13px;
      height: 13px;
      top: 7px;
      left: -21px;
      border-radius: 50%;
      background-image: none;
      background: white;
    }

    .custom-control-input:checked ~ .custom-control-label::before{
      width: 23px;
      height: 23px; 
      margin-left: -2px;
    }
 
  } 
  
  .active-radio {
    border: 2px solid #6DB1B1;
    border-radius: 8px;  
    padding: 3px 10px 5px; 
  }

  .card_wrapper {
    border: 1px solid #afdaf5;
    border-radius: 6px !important;
    font-family: BrandonText;

    @media only screen and (min-width: 1000px) {
      padding: 27px 45px;       
    }

    label {
      color: #6d7278;
      font-size: 14px;
    }
    input {
      color: #1c1634
    }

    .MuiInput-underline.Mui-disabled:before {
      border-bottom-style: unset;
    } 
  
  }

  
}

