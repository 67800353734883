@media only screen and (max-width: 500px){
    .custom_field_record_pp_form{
        margin-bottom: 20px!important;
    }
    .custom_field_record_pp_form_request_id{
        margin-bottom: 25px!important;
    }
    .custom_field_record_pp_form_request_id_not_edit{
        margin-bottom: 20px!important;
    }
}
@media only screen and (min-width: 1024px){
    .custom_field_record_pp_form_request_id{
        margin-bottom: 24px!important;
    }
}