#ButtonShowRequestInfo {
  width: fit-content;
  &:hover {
    #HoverRequestInfo {
      visibility: visible;
      opacity: 1;
    }
  }
}
#ModalRequestInfo {
  max-width: 100%;
  visibility: visible;
  transition: visibility 0s, opacity 0.3s ease;
  opacity: 1;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border-radius: 0.3rem;
  outline: 0;
  .request-details {
    background: white;
  }
}

.request_detail_modal_wrapper{
  .modal-content{
    width: 620px;
    @media only screen and (max-width: 1024px){
      width: 100%;
    }
  }
}