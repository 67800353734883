@import "@iso/assets/variables";

.form-password-expired-container {
  font-family: BrandonText;
  .img-container {
    position: relative;
    display: block;
    height: 150px;
    background-color: $blueSky_color;
  }

  .image_cloud {
    width: 100%;
    position: absolute;
    bottom: -35px;
  }

  .text-msg {
    font-size: 18px;
    margin-top: -20px;
    margin-bottom: 30px;
  }

  .hippie-blue-sign-in-button {
    width: 85%;
    border: none;
    background-color: $hippie-blue-color !important;
  }
}
