@import "@iso/assets/variables.scss";

.quote__carousel{
  width: 100%;

  .carousel-indicators {
    bottom: 10px;
    margin-bottom: 28px;
    li {
      width: 8px;
      height: 8px;
      border: 1px solid $hippie-blue-color;
      border-radius: 50%; 
      margin-top: 40px;


      &:not(:first-child){
        margin-left: 16px;
      }

      &.active{
        background: $hippie-blue-color;
        border: 1px solid $hippie-blue-color;
      }
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
      bottom: -10px;
    }
    @media only screen and (max-width: 375px){
      bottom: -11px;
    }
  }
}