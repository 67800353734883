@font-face {
  font-family: 'BrandonText';
  font-weight: bold;
  src: local('BrandonText'), url(../fonts/BrandonText-Bold.otf) format('opentype');
}

@font-face {
  font-family: 'BrandonText';
  font-weight: lighter;
  src: local('BrandonText'), url(../fonts/BrandonText-Light.otf) format('opentype');
}

@font-face {
  font-family: 'BrandonText';
  font-style: italic;
  src: local('BrandonText'), url(../fonts/BrandonText-RegularItalic.otf) format('opentype');
}

@font-face {
  font-family: 'BrandonText';
  src: local('BrandonText'), url(../fonts/BrandonText-Regular.otf) format('opentype');
}