.complete_request_modal_wrapper{
  .custom_notice {
    font-size: 12px;
  }
  .form-group{
    margin-bottom: 0;
  }

  .modal-content{
    width: 620px;
    @media only screen and (max-width: 1024px){
      width: 100%;
    }
    .completed-msg{ 
      font-size:16px;
      padding: 10px 20px 10px;
      color: #000000;
      width: 550px;
      @media only screen and (max-width: 1024px){
        width: 450px;
      } 

      @media only screen and (max-width: 500px){
        width: 100%;
      } 
      &::placeholder{
        color :#6c757d;
        opacity: 0.7;
      }
    }

    .timepicker_wrapper{
      .MuiInput-underline:before,
      .MuiInput-underline:after{
        border: none;
      }
      .MuiInput-root{
        border: 1px solid #ced4da;
        border-radius: 4px;
      }
      .MuiInputBase-input {
        padding: 6px 14px 7px;
        height: 25px;
      }
      @media only screen and (min-width: 768px){
        width: 350px;
      } 
    } 

    .react-datepicker__input-container{
      @media only screen and (min-width: 768px){
        width: 350px;
      }
    } 

    .hippie-blue-button{
      @media only screen and (min-width: 768px){
        width: 236px;
      }
    }

    .my-control {
      .MuiFormLabel-root {
        .for-focus {
          display: none;
        }
        &.Mui-focused {
          color: unset !important;
  
          .for-focus {
            display: unset;
          }
          .for-no-focus {
            display: none;
          }
        }
      }
  
      .MuiFilledInput-multiline{
        padding-top: 10px; 
        
      }
  
      .MuiFormControl-fullWidth{
        width: 345px;
      }
  
      .MuiFilledInput-root {
        background: none !important;
        border: 1px solid #ced4da !important;
        border-radius: 6px;
        text-align: center!important;
        font-size:16px;
        padding: 10px 20px 10px;
        width: 350px; 
        @media only screen and (max-width: 500px){
          width: 100%;
        }
      }
  
      .MuiFilledInput-root:focus-within {
        //border: 1px solid #52b9d4 !important;
      }
  
      .MuiFilledInput-underline:before,
      .MuiFilledInput-underline:after {
        border-bottom: none !important;
      }
      &.user_name_completed_request_admin {
        textarea {
          height: 24px;
          line-height: 24px;
        }
      }
    }
  }


}