$mint-tulip-background: #cee8f6;
$hippie-blue-background: #499e9e;
$hippie-gray-background: #e5e5e5;
$gray-color: gray;
$light-gray-color: #999999;
$lighter-gray-color: #eeeeee;
$hippie-blue-color: #499e9e;
$blue-facebook-color: #0078ed;
$lighter-hippie-blue-color: rgba(73, 158, 158, 0.05);
$oracle-color: #336c6c;

$overdue-color: #990000;
$incomplete-color: #6aa84f;
$unassigned-color: #ff9900;
$paused-color: #999999;
$accepted-color: $paused-color;
$completed-color: $paused-color;
$declined-color: $paused-color;

$pending-color: #ff9900;
$approved-color: #009e0f;
$reject-color: #990000;

$flag_red: #990000;
$flag_yellow: #ff9900;
$flag_purple: #9900ff;
$flag_white: #eeeeee;

$lighter-pink-color: #f6ccdb;
$black-color: #1c1634;
$blueSky_color: #afdaf5;
$blue_light_color: #52b9d4;
$gray-light-color: #ced4da;
$gray-ish-color: #F5F8FD;
$gray-muted-color: rgba(0, 0, 0, 0.4);