@import "@iso/assets/variables.scss"; 
#paymentMethodwrapper{
    border: 1px solid #afdaf5;
    border-radius: 6px;
    font-size: 18px;
    margin-bottom: 13px;
    @media only screen and (min-width: 1024px){
      margin-bottom: 25px;
    }
    &.custom_payment_method_wrapper{
      .btn{
        @media only screen and (max-width: 376px){
          margin: 0 30px 0 30px!important;
        }
        @media only screen and (min-width:377px) and (max-width: 500px){
          margin: 0 30px 0 30px!important;
        }
      }
    }
    .btn{
      padding: 0 14px;
      background-color: #DBDEE7; 
      border:none; 
      font-size: 15px;
    }
    .paymentMethodTitle{
      color: $black-color;
      font-weight: 600;
      width: 165%;
    }
    .hippie-btn{
      color: $hippie-blue-color; 
      cursor: pointer;
    }  
  @media only screen and (max-width: 500px){
    margin-top: 14px;
  }
  .custom_add_payment_method_wrapper{
    @media only screen and (max-width: 376px){
      margin: 0;
    }
    @media only screen and (min-width: 376px) and (max-width: 500px){
      margin: 0;
    }
  }

  .login-span {
    cursor: pointer;    
  }
}

.payment-none-user{
  padding: 40px 25px 22px!important;
}

.payment-user{
  padding: 40px 33px 22px!important;
}
.card_payment_method_wrapper{
  @media only screen and (max-width: 376px){
    max-width: 330px!important;
    margin: auto;
    padding: 0!important;
  }
  @media only screen and (min-width: 377px) and (max-width:500px){
    max-width: 365px!important;
    margin: auto;
    padding: 0!important;
  }
}
#cardPayMethodeWrapper{
  @media only screen and (max-width: 376px){
    padding: 25px 30px;
  }
  @media only screen and (min-width: 377px) and (max-width:500px){
    padding: 25px 30px;
  }
  .custom_btn{
    @media only screen and (max-width: 500px){
      padding: 0px 30px 0px 30px;
    }
  }
}
.custom_payment_method_wrapper{
  #cardPayMethodeWrapper{
    @media only screen and (max-width: 376px){
      padding: 25px 30px 0px 30px;
    }
    @media only screen and (min-width: 377px) and (max-width:500px){
      padding: 25px 30px 0px 30px;
    }
  }
}
.power-stripe-container{
  @media only screen and (max-width: 500px){
    text-align: center;
    margin-bottom: 40px!important;
  }
}
.visa_paypal_img_space{
  @media only screen and (min-width: 1024px){
    margin-bottom: 46px;
  }
  @media only screen and (max-width: 500px){
    display: flex!important;
    margin-bottom: 20px;
    justify-content: space-between;
    margin-left: -43px!important;
  }
}
.custom_visa_master_img{
  @media only screen and (max-width: 376px){
    margin-left: -10px!important;
    margin-bottom: 20px!important;
  }
  @media only screen and (min-width: 377px) and (max-width: 500px){
    margin-left: -10px!important;
    margin-bottom: 20px!important;
  }
  .custom_paypal_img{
    @media only screen and (max-width: 500px){
      margin-left: 0px!important;
    }
  }
}
.border_bottom_visa_paypal{
  @media only screen and (min-width: 992px){
    border-bottom: 3px solid #499e9e;
  }
  @media only screen and (min-width: 501px) and (max-width: 769px){
    border-bottom: 3px solid #499e9e;
  }
}


