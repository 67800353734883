#complete_request_container {
  text-align: center;
  box-shadow: inset 0px 1px 0px rgba(73, 158, 158, 0.1);
  margin-bottom: 35px;
  padding-top: 15px;
  @media only screen and (max-width: 500px) {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  #complete_request__button {
    height: 60px;
    width: 303px;
    border-radius: 6px;
    font-size: 20px;
    font-weight: bold;
    font-family: BrandonText;
    text-align: center;
    @media only screen and (max-width: 500px) {
      width: 100%;
    }
  }
}

@media only screen and (min-width: 500px) {
  #complete_request_container {
    margin-bottom: 0px;
    padding-top: 0px;
  }
}
