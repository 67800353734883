#oops-popup{
    max-width: 800px;
    padding: 50px;
    font-family: BrandonText;

    .status {
        color: #afdaf5;
        font-size: 18px;
        font-weight: bold;
        text-align: center;
        margin-bottom: 60px;
    }

    .title {
        font-weight: 800;
        text-align: center;
        margin-bottom: 15px;
    }

    @media only screen and (min-width: 700px) {
        padding: 150px;        
    }

    .color-hippie-blue{
        color : #499e9e!important;
        cursor: pointer;
      }

      .my-control {
        .MuiFormLabel-root {
          font-family: BrandonText !important;
          .for-focus {
            display: none;
          }
          &.Mui-focused {
            color: unset !important;
    
            .for-focus {
              display: unset;
            }
            .for-no-focus {
              display: none;
            }
          }
        }
        .MuiFilledInput-root {
          background: none !important;
          border: 1px solid #ced4da !important;
          border-radius: 6px;
        }
    
        .MuiFilledInput-root:focus-within{
          border: 1px solid #52B9D4!important;
        }
    
        .MuiFilledInput-underline:before, .MuiFilledInput-underline:after {
          border-bottom: none !important;
        }
      }

      
.hippie-blue-sign-in-button{
    height: 60px;
    font-weight: 600 !important;
    font-size: 20px !important;
    border-radius: 6px !important;
    border: none !important;
    background-color: #499e9e !important;
}

}