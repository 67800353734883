@import "@iso/assets/variables";

.request_item_wrapper {
  .request_item_value_css {
    // height: 30px;
    // max-height: 30px;
    line-height: 30px;
  }
  .request_flagged {
    &.red {
      color: $flag_red;
    }
    &.yellow {
      color: $flag_yellow;
    }
    &.purple {
      color: $flag_purple;
    }
  }

  @media only screen and (max-width: 500px) {
    .margin-left-content {
      margin-left: 12px;
    }
  }
}
