@import "bootstrap/scss/bootstrap.scss";
@import "@iso/assets/variables.scss";

.app_container {
  width: 100%;
  //overflow-x: hidden;
  @media only screen and (min-width: 992px) {
    max-width: 1921px !important;
  }
  @media only screen and (min-width: 1200px) {
    max-width: 1921px !important;
  }
  @media only screen and (min-width: 1440px) {
    max-width: 1440px !important;
  }
}

.container-full-width {
  width: 100vw;
  position: relative;
  margin-left: -50vw;
  left: 50%;
  //margin-left: calc(50% - 50vw);
  //margin-right: calc(50% - 50vw);
}

.container-full-width > .container-fixed {
  margin-left: auto;
  margin-right: auto;
  max-width: 1920px;
  @media (min-width: 1440px) and (max-width: 1919px) {
    max-width: 1440px;
  }
}

.bg-blue-sky-half {
  @media only screen and (min-width: 768px) { // tablet only
    background: linear-gradient(90deg, $blueSky_color 50%, #ffffff00 50%);
  }
}

.bg-blue-sky-half-reverse {
  @media only screen and (min-width: 1440px) { // tablet only
    background: linear-gradient(90deg, #ffffff00 #{'calc(100vw - (100vw - 1920px) / 2)'}, rgba(175, 218, 245, 0.6) #{'calc((100vw - 1920px) / 2)'});
  }
}

.bg-blue-sky-half-reverse-2 {
  @media only screen and (min-width: 1440px) { // tablet only
    background: linear-gradient(90deg, #ffffff00 #{'calc(100vw - (100vw - 1440px) / 2)'}, rgba(175, 218, 245, 0.6) #{'calc((100vw - 1440px) / 2)'});
  }
}


.bg-for-request-half {
  @media only screen and (min-width: 768px) { // tablet only
    background: linear-gradient(90deg, #ffffff00 58.84%, #52b9d4 41.16%);
  }
}

.mint-tulip-background {
  background-color: $mint-tulip-background;
}

.text-hippie-blue {
  color: $hippie-blue-background;
}

.hippie-blue-background {
  background-color: $hippie-blue-background;
}

.border-bottom-hippie-blue {
  border-bottom: 3px solid $hippie-blue-background;
}

.hippie-blue-button,
.hippie-blue-button:hover {
  background-color: $hippie-blue-background !important;
  border: none !important;
}

.light-gray-button,
.light-gray-button:hover {
  background-color: $light-gray-color !important;
  border: none !important;
}

.lighter-pink-color,
.lighter-pink-color:hover {
  background-color: $lighter-pink-color !important;
  border: none !important;
}

.error-message {
  min-height: 24px;
  @media only screen and (max-width: 500px) {
    min-height: 0px;
    margin-bottom: 5px !important;
  }
}

.error-step3 {
  margin-left: -6px;
  @media only screen and (max-width: 500px) {
    margin-left: 0px;
  }
}

.error_contact_msg {
  font-size: 14px;
  color: #d45252 !important;
  font-style: italic;
}

.grey-text {
  color: $gray-color;
}

.hippie-blue-text {
  color: $hippie-blue-color;
}

.small-text {
  font-size: 14px;
}

.non-underline-link {
  text-decoration: none;
}

.vertical-menu {
  a {
    color: white;
    font-weight: bold;
  }
}

.hippie-blue-link {
  color: $hippie-blue-color !important;

  &:hover {
    color: $oracle-color !important;
    text-decoration: none;
  }
}

.oracle-color {
  color: $oracle-color;
}

.light-gray-color {
  color: $light-gray-color;
}

.gray-color {
  color: $gray-color;
}

.explore-account {
  font-size: 20px;
  color: #000000;

  &:hover {
    text-decoration: none;
    color: #000000;
  }
}


// Fix scrolling issues
html.unset-height {
  body,
  #root {
    height: unset;
    min-height: unset;

    .wrapper {
      height: unset !important;
    }
  }
}

html,
body,
#root,
.App,
.app-main,
.app-main.container,
.app-main__outer,
.app-main__inner,
.admin-portal.sign-in-container {
  height: 100%;
  min-height: 100vh;

  .wrapper {
    height: calc(100vh - 80px);
  }

  button.navbar-toggler {
    border: 0;
    outline: none;
  }
}

.prayer-portal,
.registration-box-container {
  min-height: 100vh;
}

.border-1px {
  border: 1px solid #999999;
  min-width: 200px;
}

@media print {
  html,
  body,
  #root,
  .App,
  .app-main,
  .app-main.container,
  .app-main__outer,
  .app-main__inner,
  .admin-portal.sign-in-container {
    min-height: 99%;
  }
}

.noWrap {
  white-space: nowrap;
}

.Mui-focused {
  fieldset {
    border: 1px solid $light-gray-color !important;
  }
}

.btn.app_btn {
  min-width: 130px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 40px;
  line-height: 25px;
  margin-bottom: 3px;
  border-color: unset !important;
}

#notice_wrong_email_btn {
  width: 150px;
  text-overflow: ellipsis;
  overflow: hidden;
  height: 40px;
  line-height: 25px;
  margin-bottom: 3px;
  border-color: unset !important;
}

.group-button-header-request {
  .app_btn {
    width: 137px;
  }
}

.btn:focus {
  outline: unset !important;
  box-shadow: unset !important;
}

span.rc-time-picker.form-control.timepicker_wrapper {
  .rc-time-picker-input {
    padding-top: 0;
    padding-left: 0;
    padding-right: 0;
    display: block;
    width: 100%;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 0px solid #ced4da;
    border-radius: 0.25rem;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  }

  .rc-time-picker-clear-icon::after {
    content: "";
  }
}

.font-size-12px {
  font-size: 12px;
}

.height_100per {
  min-height: 100%;
}

.bg_lighter_hippie_blue {
  background-color: $lighter-hippie-blue-color;
}

.min_height_100vh {
  min-height: 100vh;
}

.MuiCheckbox-colorSecondary.Mui-checked {
  color: $hippie-blue-color !important;
}

.manage-notification {
  .not-edit-form {
    .MuiCheckbox-colorSecondary.Mui-checked {
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }

  .disabled-checkbox {
    .MuiCheckbox-colorSecondary.Mui-checked, p {
      color: rgba(0, 0, 0, 0.26) !important;
    }
  }
}

.remove-border-bottom-muiInput {
  &.MuiInput-underline:hover:not(.Mui-disabled):before,
  &.MuiInput-underline:before,
  &.MuiInput-underline:after {
    border-bottom: none;
  }
}

.bg_blueSky {
  background-color: $blueSky_color;
}

.bg_blueSky-with-margin {
  background-image: linear-gradient(180deg, $blueSky_color calc(100% - 20px), #FFFFFF00 20px);
  @media (min-width: 1920px) {
    height: 698px;
  }
}


.prayer-hippe-button {
  background-color: $hippie-blue-color;
  color: white !important;
  border-radius: 6px;
  box-shadow: inset 0px -3px 0px rgba(0, 0, 0, 0.2);
  margin-left: 5px;
  font-weight: bold;
  font-family: BrandonText;
  height: 48px;
  width: 173px;
  padding: 0.65rem 2rem;

  &.mobile {
    width: 160px;
  }
}

.request_menu_mobile_btn {
  margin-top: 40px;
}

.black-link {
  color: $black-color !important;
  font-family: BrandonText;
  font-size: 16px;
}

.a_decorate_none {
  color: inherit;

  &:hover {
    color: inherit;
    text-decoration: none;
  }
}

.margin_top_20px {
  margin-top: 20px;
}

.input_search_data_table {
  padding: 2px 5px 2px 10px;
  margin-bottom: 5px;

  @include media-breakpoint-down(md) {
  }
  @include media-breakpoint-up(md) {
    position: absolute !important;
    top: 15px;
    right: 15px;
  }
}

.custom-control-input:checked ~ .custom-control-label::before {
  border-color: $hippie-blue-color;
  background-color: $hippie-blue-color;
}

.header_back_link {
  font-size: 16px;
  line-height: 38px;
  float: right;
}

.font-size_error {
  font-size: 0.75rem;
}

.navbar-nav {
  &.header-tablet {
    a button {
      padding-left: 1rem;
      padding-right: 1rem;
    }

    .nav-link {
      padding-left: 21.75px;
      padding-right: 21.75px;

      &:last-child {
        padding-left: 12.25px;
        padding-right: 9px;
      }
    }
  }
}

@media (min-width: 768px) and (max-width: 1024px) {
  .navbar-inverse {
    height: 80px !important;
  }
  .receiveModalContainer {
    background: rgba(1, 44, 60, 0.6);

    .modal-dialog {
      max-width: 500px !important;
      top: 0;
      bottom: 0;
      position: absolute;
      left: 0;
      right: 0;
      height: fit-content;
      margin: auto;

      .switch-signIn-button {
        margin-bottom: 24px !important;
      }

      .modal-content {
        width: 500px !important;
      }
    }

    &.asGuest {
      .modal-dialog {
        width: 600px !important;
        max-width: 600px !important;
        margin-top: 151px !important;
      }

      .modal-content {
        width: 600px !important;
      }
    }

    &.signUp {
      .modal-dialog {
        height: 740px !important;
      }

      .modal-content {
        height: 740px !important;
      }

      #receive_confirm_modal {
        padding: 46px 40px 40px 40px;

        .control-hr {
          margin-top: 10px;
          margin-bottom: 10px;
        }

        .switch-signIn-button {
          margin-top: -15px !important;
          margin-bottom: 10px !important;
        }
      }
    }
  }
  .modalSignInContainer {

    .forgotPasswordContainer {
      padding: 36px 55px 60px 55px !important;

      > div {
        //padding: 0!important;
        .fontStyle {
          font-size: 18px;
          line-height: 32px;
        }
      }
    }
  }
  .checkemailmodalContainer {
    padding: 40px 50px 30px 50px !important;

    h2 {
      font-size: 36px !important;
    }

    .fontStyle {
      font-size: 18px !important;
      line-height: 32px;
      margin-bottom: 10px !important;
    }

    .close {
      margin-top: -70px !important;
      margin-right: -50px !important;
    }
  }
  #continue_as_guest__wrapper {
    padding: 90px 105px !important;

    .suggest-sign-up {
      margin-top: 0 !important;
    }
  }
  @media (min-width: 768px) and (max-width: 1024px) {
    .modal-dialog {
      max-width: 500px !important;
      top: 0;
      bottom: 0;
      position: absolute;
      left: 0;
      right: 0;
      height: fit-content;
      margin: auto;

      .switch-signIn-button {
        margin-bottom: 24px !important;
      }

      .modal-content {
        width: 500px !important;
      }
    }
  }
  .top-view-tablet {
    @media (max-width: 768px) and (min-width: 600px) {
      padding-top: 40px;
      background-color: #f5f9fb;
    }
  }

}
@media (min-width: 1025px) {
  .popup-beta {
    .modal-dialog {
      max-width: 600px!important;
      width: 600px!important;
    }
  }
}
@media (min-width: 768px) {
  .my-dialog {
    .modal-dialog {
      max-width: 550px!important;
    }
  }
}
.termly-cookie-preference-button {
  background: white;
  width: 165px;
  height: 30px;
  border-radius: 3px;
  border: 1px solid #5f7d9c;
  font-family: Roboto, Arial;
  font-size: 10px;
  color: #5f7d9c;
  font-weight: 600;
  box-sizing: border-box;
  cursor: pointer;
  padding: 0;
  outline: none;
}
