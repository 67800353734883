@import "@iso/assets/variables.scss";
* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
body {
  padding: 0;
  margin: 0;
}
#accessDenied {
  position: relative;
  height: 100vh;
  .accessDenied {
    position: absolute;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
  }
  .hippie-blue-color{
    color: $hippie-blue-color;
  }
}
.accessDenied {
  max-width: 600px;
  width: 100%;
  line-height: 1.4;
  text-align: center;
  .accessDeniedTitle {
    position: relative;
    height: 120px;
    h1 {
      font-family: 'Montserrat', sans-serif;
      position: absolute;
      left: 50%;
      top: 50%;
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
      transform: translate(-50%, -50%);
      font-size: 60px;
      font-weight: 900; 
      margin: 0px;
      color: #262626;
      text-transform: uppercase;  
      width: 100%;
    }
    h3 {
      font-family: 'Cabin', sans-serif;
      position: relative;
      font-size: 16px;
      font-weight: 700;
      text-transform: uppercase;
      color: #262626;
      margin: 0px;
      letter-spacing: 3px;
      padding-left: 6px;
    }
  }
  h2 {
    font-family: 'Cabin', sans-serif;
    font-size: 20px;
    font-weight: 400;
    text-transform: uppercase;
    color: #000;
    margin-top: 0px;
    margin-bottom: 25px;
  }
}
@media only screen and (max-width: 767px) {
  .accessDenied {
    .accessDeniedTitle {
      height: 100px;
      h1 {
        font-size: 60px;
      }
    }
  }
}
@media only screen and (max-width: 480px) {
  .accessDenied {
    padding: 10px;
    .accessDeniedTitle {
      height: 90px;
      h1 {
        font-size: 35px; 
      }
    }
    h2 {
      font-size: 16px;
    }
  }
}
