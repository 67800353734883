.content-other{
  font-size: 18px;
  color: #000000;
}

.content-orthodox{
  padding: 0 30px;  
  color: #424242;
  @media only screen and (max-width: 500px){
    padding: 0;
  }
}

.content-catholic{
  padding: 0 10px;  
  color: #424242;
  @media only screen and (max-width: 500px){
    padding: 0;
  }
}

.content-anglican{
  padding: 0 20px;  
  color: #424242;
  @media only screen and (max-width: 500px){
    padding: 0;
    &.mt-3{
      margin-top: 31px!important;
    }
  }
}